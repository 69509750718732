import { toast } from 'react-toastify';

export function showError(err: any) {
  const errorData = err?.response?.data?.data;
  if (errorData && typeof errorData === 'object') {
    Object.values<string[]>(errorData).forEach((messages) => {
      toast.error(messages[0], { containerId: 'B' });
    });
  }
}
