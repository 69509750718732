import _ from 'lodash';
import { AnyAction } from 'redux';
import types from './types';

const initialState: StoreRoot = {
  loadings: [],
  errors: {},
  restaurantData: [],
  articles: [],
  categories: [],
  articleOptions: [],
  articleOptionNames: [],
};

export default function reducer(
  state = initialState,
  { type, payload }: AnyAction
): typeof initialState {
  switch (type) {
    case types.ADD_LOADING:
      return {
        ...state,
        loadings: _.uniq([...state.loadings, payload]),
      };
    case types.REMOVE_LOADING:
      return {
        ...state,
        loadings: state.loadings.filter((item) => item !== payload),
      };
    case types.ADD_ERROR: {
      const errorKey = Object.keys(payload)[0];

      const newErrors = { ...state.errors };
      newErrors[errorKey] = payload[errorKey];

      return {
        ...state,
        errors: newErrors,
      };
    }
    case types.REMOVE_ERROR: {
      const errorKey = Object.keys(payload)[0];

      const newErrors = { ...state.errors };
      delete newErrors[errorKey];

      return {
        ...state,
        errors: newErrors,
      };
    }
    case types.RESET_STATE:
      return initialState;
    case types.SET_RESTAURANT_DATA:
      return {
        ...state,
        restaurantData: payload,
      };
    case types.SET_ARTICLES:
      return {
        ...state,
        articles: payload,
      };
    case types.SET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case types.SET_ARTICLE_OPTIONS:
      return {
        ...state,
        articleOptions: payload,
      };
    case types.SET_ARTICLE_OPTION_NAMES:
      return {
        ...state,
        articleOptionNames: payload,
      };
    default:
      return state;
  }
}
