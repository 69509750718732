import React, { useEffect, useRef, useState } from 'react';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import dinner_black from '../assets/images/eatsy.png';
import './style.css';
import i18n from 'i18next';
import LanguageDropDown from './Form/LanguageDropDown';
import { Directions } from '@material-ui/icons';

const Header = ({ login = false }) => {
  const sidebarToggle = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState('transparent');
  const [showWhiteLogo, setShowWhiteLogo] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 993 && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
      sidebarToggle.current.classList.toggle('toggled');
    }
  }, [isOpen, color, showWhiteLogo]);

  const toggle = () => {
    if (isOpen) {
      setColor('transparent');
    } else {
      setColor('dark');
    }
    setIsOpen(!isOpen);
    setShowWhiteLogo(!showWhiteLogo);
    // @ts-ignore
    document.getElementById('bellIcon')?.classList.toggle('text-white');
    // @ts-ignore
    // document.getElementById('headerLogo')?.classList.toggle('text-white');
  };

  const openSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    sidebarToggle.current.classList.toggle('toggled');
    // @ts-ignore
    document.getElementById('border-top-radius').classList.toggle('border-top-radius');
  };
  const [localLang, setLocalLang] = useState(i18n.language);
  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={color}
      expand="lg"
      className={`navbar-absolute fixed-top ${
        color === 'transparent' ? 'navbar-transparent ' : ''
      }`}
      style={{ borderBottom: `${login ? 'none' : ''}` }}
    >
      <Container fluid style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <div className="navbar-wrapper">
          <div className="navbar-toggle" style={{ display: `${login ? 'none' : ''}` }}>
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={openSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand
            style={{ display: `${login ? 'none' : ''}` }}
            href="/"
            id="headerLogo"
            className="d-md-none d-sm-block"
          >
            <img src={showWhiteLogo ? dinner_black : dinner_black} className="headerLogo" alt="" />{' '}
            Restaurant Place
          </NavbarBrand>

          <LanguageDropDown />
        </div>
        <NavbarToggler style={{ display: `${login ? 'none' : ''}` }} onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            <NavItem />
            <NavItem />
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
