export const localization = {
  resources: {
    // English: {
    //   translation: {
    //     Bewerk_restaurant: {
    //       editRestuarant_title: 'Bewerk restaurant',
    //       editRestuarant_label_name:"Naam restaurant :",
    //       editRestuarant_placeholder_name:"Naam restaurant",
    //       editRestuarant_label_address:"Locatie",
    //       editRestuarant_placeholder_address:"Restaurant Locatie",
    //       editRestuarant_label_availability_zipCodes:"Availablity zone :(comma seperated)",
    //       editRestuarant_placeholder_availability_zipCodes:"Restaurant availablity zone zipcodes",
    //       editRestuarant_label_postCode:"Postcode",
    //       editRestuarant_placeholder_postCode:"Restaurant Postcode",
    //       editRestuarant_label_benner:"Banner",
    //       editRestuarant_Choose_File_benner:"Choose file Banner",
    //       editRestuarant_banner_preview:"Vorige afbeelding",
    //       editRestuarant_label_logo:"Update Logo",
    //       editRestuarant_Choose_file_logo:"Choose file Update Logo",
    //       editRestuarant_logo_preview:"Vorige afbeelding",
    //       editRestuarant_label_numberOfTables:"Aantal tafels met unieke qr code",
    //       editRestuarant_placeholder_numberOfTables:"Enter number",
    //       editRestuarant_label_staus:"Status restaurant",
    // editRestuarant_label_visible_on_webapp: 'visible on web/ app',
    //       editRestuarant_label_preparation_time:"Bereidingstijd in min",
    //       editRestuarant_placeholder_preparation_time:"Enter number",
    //       editRestuarant_label_msg_restuarant_close:"Bericht indien gesloten",
    //       editRestuarant_label_europaBankUID:"Europabank UID",
    //       editRestuarant_placeholder_europaBankUID:"Enter UID",
    //       editRestuarant_label_europaBank_client_secret:"Europabank client secret",
    //       editRestuarant_placeholder_europaBank_client_secret:"Enter client secret",
    //       editRestuarant_label_europaBank_server_secret:"Europabank server secret",
    //       editRestuarant_placeholder_europaBank_server_secret:"Enter server secret",
    //       editRestuarant_label_europaBank_url:"Europabank url",
    //       editRestuarant_placeholder_europaBank_url:"Enter URL",
    //       editRestuarant_label_pay_online:"Online laten betalen?",
    //       editRestuarant_option_pay_online_on:"On",
    //       editRestuarant_option_pay_online_off:"Off",
    //       editRestuarant_placeholder_payOnSpot:"Ter plaatse laten betalen?",
    //       editRestuarant_option_payOnsite_on:"On",
    //       editRestuarant_option_payOnsite_Off:"Off",
    //       editRestuarant_label_serviceRequest_at_table:"Service aanvraag aan tafel",
    //       editRestuarant_option_serviceRequest_on:"On",
    //       editRestuarant_option_serviceRequest_off:"Off",
    //       editRestuarant_label_billRequest_at_table:"Rekening aanvraag aan tafel",
    //       editRestuarant_option_billRequest_at_table_on:"On",
    //       editRestuarant_option_billRequest_at_table_off:"Off",
    //       editRestuarant_label_active_sms:"Activeer smsfunctie",
    //       editRestuarant_label_active_sms_on:"On",
    //       editRestuarant_label_active_sms_off:"Off",
    //       editRestuarant_label_serviceCostType:"Service cost type",
    //       editRestuarant_option_serviceCostType_fixed_amount:"Fixed amount",
    //       editRestuarant_option_serviceCostType_percentage:"Percentage",
    //       editRestuarant_label_serviceCost_fixed_amount:"Service cost fixed amount",
    //       editRestuarant_placeholder_serviceCost_fixed_amount:"Price",
    //       editRestuarant_placeholder_serviceCost_percentage_amount:"Service cost percentage",
    //       editRestuarant_label_delivery_cost:"Delivery Cost",
    //       editRestuarant_placeholder_delivery_cost:"Enter Delivery Cost",
    //       editRestuarant_label_free_delivery_amount:"Free Delivery (above amount)",
    //       editRestuarant_placeholder_free_delivery_amount:"Enter Free Delivery (above amount)",
    //       editRestuarant_label_pageTitle:"Page Title",
    //       editRestuarant_label_metaTitles:"Meta Titles(comma seperated)",
    //       editRestuarant_placeholder_metaTitles:"Enter Meta titles",
    //       editRestuarant_label_metaDescription:"Meta Descriptions(comma seperated)",
    //       editRestuarant_placeholder_metaDescription:"Enter Meta Descriptions",
    //       editRestuarant_label_metaKeyword:"Meta Keywords(comma seperated)",
    //       editRestuarant_placeholder_metaKeyword:"Enter meta keywords",
    //       editRestuarant_label_slug:"Slug",
    //       editRestuarant_placeholder_slug:"Enter Slug",
    //       editRestuarant_label_max_num_orders:"Max aantal bestellingen",
    //       editRestuarant_placeholder_max_num_orders:"Enter Max aantal bestellingen",
    //       editRestuarant_label_per_time_interval:"Per tijdsinterval (in min)",
    //       editRestuarant_placeholder_per_time_interval:"Enter Per tijdsinterval",
    //       editRestuarant_label_restuarant_email:"Email restaurant",
    //       editRestuarant_placeholder_restuarant_email:"Enter Email restaurant",
    //       editRestuarant_label_telephone:"Tel restaurant",
    //       editRestuarant_placeholder_telephone:"Enter Tel restaurant",
    //       editRestuarant_navigate_site:" Visit Your URL!",
    //       editRestuarant_successfully_updated:"Updated Successfully!",
    //       editRestuarant_button_save:"save"
    //     },
    //     Opening_hours:{
    //       openingHrs_button_add:"Voeg tijdslot toe",
    //       openingHrs_table_title:"Openingsuren",
    //       openingHrs_table_day:"Dag",
    //       openingHrs_table_from:"Vanaf",
    //       openingHrs_table_until:"Tot",
    //       openingHrs_table_delivery:"Levering",
    //       openingHrs_table_takeaway:"Afhaal",
    //       openingHrs_table_action:"Actie"
    //     },
    //     Add_Edit_hours:{
    //       addOpeningHrs_title:"Add New Working Hour",
    //       editOpeningHrs_title:"Edit Working Hour",
    //       addOpeningHrs_label_select_weekday:"Choose Day Of Week",
    //       openingHrs_weekday_mon:"Maandag",
    //       openingHrs_weekday_Tues:"Dinsdag",
    //       openingHrs_weekday_Wed:"Woensdag",
    //       openingHrs_weekday_Thurs:"Donderdag",
    //       openingHrs_weekday_fri:"Vrijdag",
    //       openingHrs_weekday_sat:"Zaterdag",
    //       openingHrs_weekday_sun:"Zondag",
    //       addOpeningHrs_label_from:"Vanaf",
    //       addOpeningHrs_placeholder_from:"Enter Vanaf",
    //       addOpeningHrs_label_until:"Tot",
    //       addOpeningHrs_placeholder_until:"Enter Tot",
    //       addOpeningHrs_label_delivery_option:"Levering optie",
    //       addOpeningHrs_option_delivery_on:"On Levering optie",
    //       addOpeningHrs_option_delivery_off:"Off Levering optie",
    //       addOpeningHrs_label_pickUp_option:"Afhaal optie",
    //       addOpeningHrs_option_pickUp_on:"On Afhaal optie",
    //       addOpeningHrs_option_pickUp_off:"Off Afhaal optie",
    //       addOpeningHrs_button_cancel:"cancel",
    //       addOpeningHrs_button_save:"save",
    //       editOpeningHrs_successfully_updated_msg:"Updated Successfully!",
    //       addOpeningHrs_successfully_add_msg:"added successfully",
    //       addOpeningHrs_successfully_delete_msg:"deleted successfully"
    //       addOpeningHrs_popup_deDelivery_Pickup:"Delivery/Pickup can't be OFF at same time !"
    //     },
    //     Tables_QR_Codes:{
    //       tableQRcode_title:"Tables QR Codes",
    //       tableQRcode_panel_number:"Tafelnummer",
    //       tableQRcode_action:"Actie",
    //       tableQRcode_action_button_title:"Maak qr code",
    //       tableQRcode_download_QR:"Download QR"
    //     },
    //     Category:{
    //       category_title:"Categories",
    //       category_button_add:"Voeg category toe",
    //       category_table_header_name:"Naam",
    //       category_table_header_Actie:"Actie"
    //     },
    //     Add_Edit_Category:{
    //       addCategory_title:"Voeg category toe",
    //       editCategory_title:"Bewerk categorie",
    //       addCategory_name:"Naam",
    //       addCategory_validation_name_empty:"please enter category name",
    //       addCategory_button_cancel:"cancel",
    //       addCategory_button_save:"Save"
    //     },
    //     Product_option_groups:{
    //       productGroup_title:"Productoptie groepen",
    //       productGroup_button_add:"Voeg groep toe",
    //       productGroup_table_header_nameOption:"Naam optie",
    //       productGroup_table_header_addOptionName:"Add Option Names",
    //       productGroup_table_header_activeOptionName:"Active Option Name",
    //       productGroup_table_header_availableOptionNames:"Available Option Names",
    //       productGroup_table_header_action:"Actie",
    //       productGroup_table_createArticalOption:"Create Article Options"
    //     },
    //     Add_Product_option:{
    //       addProductGroup_title:"Add New Options Group",
    //       addProductGroup_label_name:"Article Option Name:",
    //       addProductGroup_placeholder_name:"Enter Name",
    //       addProductGroup_button_cancel:"cancel",
    //       addProductGroup_button_save:"save"
    //     },
    //     Edit_Product_option:{
    //       editProductGroup_title:"Edit Options Group",
    //       editProductGroup_label_selectArtical:"Select Article Options",
    //       editProductGroup_label_minvalue:"Enter Min value",
    //       editProductGroup_label_maxValue:"Enter Max value",
    //       editProductGroup_button_cancel:"cancel",
    //       editProductGroup_button_save:"save"
    //     },
    //     Create_Article_Options:{
    //       createArticalOption_title:"Create Article Options",
    //       createArticalOption_alias:"Alias",
    //       createArticalOption_label_choice:"Choice",
    //       createArticalOption_label_price:"Price",
    //       createArticalOption_label_minSelection:"Min Selection",
    //       createArticalOption_label_maxSelection:"Max Selection",
    //       createArticalOption_button_submit:"submit"
    //     },
    //     Article_Options_table:{
    //       articalOption_title:"Beheer productopties",
    //       articalOption_table_header_options:"Optie",
    //       articalOption_table_header_choiceName:"Keuze optie",
    //       articalOption_table_header_choicePrice:"Prijs optie",
    //       articalOption_table_header_optionName:"Naam optie",
    //       articalOption_table_header_action:"Actie"
    //     },
    //     Edit_Article_Options_table:{
    //       editArticalOption_title:"Edit Article Option",
    //       editArticalOption_label_choice:'Choice',
    //       editArticalOption_placeholder_choice:"Enter choice",
    //       editArticalOption_label_price:"Prijs",
    //       editArticalOption_button_submit:"submit",
    //       editArticalOption_button_cancel:"cancel"
    //     },
    //     Manage_products:{
    //       manageProduct_title:"Beheer producten",
    //       manageProduct_button:"Voeg product toe",
    //       manageProduct_table_header_productName:"Naam product",
    //       manageProduct_table_header_price:"Prijs",
    //       manageProduct_table_header_category:"Categorie",
    //       manageProduct_table_header_pickup_delivery:"BTW % bij pickup/levering",
    //       manageProduct_table_header_spot:"BTW % bij ter plaatse",
    //       manageProduct_table_header_moreInfo:"Meer informatie",
    //       manageProduct_table_header_ingredients:"Ingredienten",
    //       manageProduct_table_header_image:"Afbeelding",
    //       manageProduct_table_header_action:"Actie"
    //     },
    //     Add_Product:{
    //       addProduct_title:"Voeg product toe",
    //       addProduct_label_image:"Add Article Image",
    //       addProduct_label_name:"Naam product:",
    //       addProduct_placeholder_name:"Enter Name",
    //       addProduct_label_price:"Prijs",
    //       addProduct_placeholder_price:"Enter Prijs",
    //       addProduct_label_category:"Categorie",
    //       addProduct_dropdown_category:"Select Categorie",
    //       addProduct_label_pickup_delivery:"BTW % bij pickup/levering",
    //       addProduct_placeholder_pickup_delivery:"Tax rate in percentage",
    //       addProduct_label_spot:"BTW % bij ter plaatse",
    //       addProduct_placeholder_spot:"Tax rate in percentage",
    //       addProduct_label_productOption:"Product Options:",
    //       addProduct_dropdown_productOption:"Select Product options",
    //       addProduct_label_ingredients:"Voeg Ingredienten toe (comma seprated):",
    //       addProduct_label_productSoldOut:"Product Sold Out",
    //       addProduct_label_moreInfo:"Meer informatie:",
    //       addProduct_placeholder_moreInfo:"information",
    //       addProduct_button_cancel:"cancel",
    //       addProduct_button_submit:"Submit"
    //     },
    //     CSV_Upload:{
    //       csvUpload_title:"Upload CSV file Here",
    //       csvUpload_label_csvFile:"Upload CSV file Here",
    //       csvUpload_button_save:"save",
    //       csvUpload_success_msg:"File Submit",
    //       csvUpload_validation_file_extention:"File Type invalid"
    //     },
    //     Orders:{
    //       orders_title:"Overzicht bestellingen",
    //       orders_filter_startDate:"Start Date",
    //       orders_filter_endDate:"End Date",
    //       orders_filter_status_pending:"pending",
    //       orders_filter_status_accepted:"accepted",
    //       orders_filter_status_rejected:"rejected",
    //       orders_button_filter:"Filter",
    //       orders_button_downloadCsv:"Download CSV",
    //       order_tabel_header_orderid:"Order ID",
    //       order_tabel_header_orderStatus:"Status bestelling",
    //       order_tabel_header_paymentStatus:"Betalingstatus",
    //       order_tabel_header_panelNumber:"Tafelnummer",
    //       order_tabel_header_customerNote:'Opmerking klant',
    //       order_tabel_header_customerName:"Naam klant",
    //       order_tabel_header_Email:"E-mail",
    //       order_tabel_header_phoneNumber:"GSM",
    //       order_tabel_header_address:"Adres",
    //       order_tabel_header_amount:"Bedrag",
    //       order_tabel_header_forWhen:"Voorwanneer",
    //       order_tabel_header_order:"Bestelling"
    //     },
    //     change_password:{
    //       changePassword_title:"Pas wachtwoord aan",
    //       changePassword_currentPassword:'Huidig wachtwoord',
    //       changePassword_newPassword:"Nieuw wachtwoord",
    //       changePassword_submit:"Submit"
    //     },
    //     sidebar: {
    //       sidebar_editRestuarant: 'Bewerk restaurant',
    //       sidebar_create_qr_code: 'Maak unieke qr codes aan',
    //       sidebar_category: 'Categoriën',
    //       sidebar_product_option_group: 'Productoptie groepen',
    //       sidebar_manage_product_options: 'Beheer productopties',
    //       sidebar_manage_product: 'Beheer producten',
    //       sidebar_csvUpload: 'CSV Upload',
    //       sidebar_orders: 'Bestellingen',
    //       sidebar_change_password: 'Pas wachtwoord aan',
    //       sidebar_logout: 'Logout',
    //     },
    //   },
    // customer: {
    //   title: 'Customer',
    //   exportButtonLabel: 'Export',
    //   table: {
    //     name: 'Name',
    //     contactDetails: 'Contact Details',
    //     totalOrderValue: 'Total Order value',
    //     createdAt: 'Date Created',
    //     totalRecords: 'Total Records'
    //   }
    // },
    Dutch: {
      translation: {
        Login: {
          login_title: 'Restaurant paneel',
          login_subtitle: 'Log in',
          login_placeholder_email: 'E-mail',
          login_placeholder_password: 'Wachtwoord',
          login_validation_email_empty: 'E-mailadres',
          login_validation_email_not_valid: 'Geef een juist e-mailadres in',
          login_validation_password_empty: 'Vul uw wachtwoord in',
          login_validation_email_password_wrong: 'E-mail of wachtwoord is fout',
          login_navigate_forget_password: 'Wachtwoord vergeten?',
          login_button: 'Log in',
        },
        Forget_Password: {
          forgetPassword_title: 'Restaurant paneel',
          forgetPassword_label_email: 'Vul uw e-mailadres is en ontvang een nieuw wachtwoord',
          forgetPassword_email_not_exists: 'E-mail bestaat niet',
          forgetPassword_success_mailSend_msg:
            'We hebben een e-mail verzonden om uw wachtwoord te herstellen.',
          forgetPassword_submit: 'Verstuur',
        },
        Bewerk_restaurant: {
          editRestuarant_title: 'Bewerk restaurant',
          editRestuarant_label_name: 'Naam restaurant :',
          editRestuarant_placeholder_name: 'Naam restaurant',
          editRestuarant_label_address: 'Locatie',
          editRestuarant_placeholder_address: 'Restaurant Locatie',
          editRestuarant_label_availability_zipCodes: 'Leverzone :(gescheiden door een komma)',
          editRestuarant_placeholder_availability_zipCodes:
            'Leverzone :(gescheiden door een komma)',
          editRestuarant_label_postCode: 'Postcode',
          editRestuarant_placeholder_postCode: 'Restaurant Postcode',
          editRestuarant_label_benner: 'Banner',
          editRestuarant_Choose_File_benner: 'Kies banner',
          editRestuarant_banner_preview: 'Vorige afbeelding',
          editRestuarant_label_logo: 'Update Logo',
          editRestuarant_Choose_file_logo: 'Kies logo',
          editRestuarant_logo_preview: 'Vorige afbeelding',
          editRestuarant_label_numberOfTables: 'Aantal tafels met unieke qr code',
          editRestuarant_placeholder_numberOfTables: 'Geen aantal in',
          editRestuarant_label_staus: 'Status restaurant',
          editRestuarant_label_visible_on_webapp: 'zichtbaar op web/app',
          editRestuarant_label_preparation_time: 'Bereidingstijd in min',
          editRestuarant_placeholder_preparation_time: 'Geen aantal in',
          editRestuarant_label_msg_restuarant_close: 'Bericht indien gesloten',
          editRestuarant_label_europaBankUID: 'Europabank UID',
          editRestuarant_placeholder_europaBankUID: 'Enter UID',
          editRestuarant_label_europaBank_client_secret: 'Europabank client secret',
          editRestuarant_placeholder_europaBank_client_secret: 'Enter client secret',
          editRestuarant_label_europaBank_server_secret: 'Europabank server secret',
          editRestuarant_placeholder_europaBank_server_secret: 'Enter server secret',
          editRestuarant_label_europaBank_url: 'Europabank url',
          editRestuarant_placeholder_europaBank_url: 'Enter URL',
          editRestuarant_label_pay_online: 'Online laten betalen?',
          editRestuarant_option_pay_online_on: 'Aan',
          editRestuarant_option_pay_online_off: 'Uit',
          editRestuarant_placeholder_payOnSpot: 'Ter plaatse laten betalen?',
          editRestuarant_option_payOnsite_on: 'Aan',
          editRestuarant_option_payOnsite_Off: 'Uit',
          editRestuarant_label_serviceRequest_at_table: 'Service aanvraag aan tafel',
          editRestuarant_option_serviceRequest_on: 'Aan',
          editRestuarant_option_serviceRequest_off: 'Uit',
          editRestuarant_label_billRequest_at_table: 'Rekening aanvraag aan tafel',
          editRestuarant_option_billRequest_at_table_on: 'Aan',
          editRestuarant_option_billRequest_at_table_off: 'Uit',
          editRestuarant_label_active_sms: 'Activeer smsfunctie',
          editRestuarant_label_active_sms_on: 'Aan',
          editRestuarant_label_active_sms_off: 'Uit',
          editRestuarant_label_serviceCostType: 'Service cost type',
          editRestuarant_option_serviceCostType_fixed_amount: 'Vast bedrag',
          editRestuarant_option_serviceCostType_percentage: 'Percentage',
          editRestuarant_label_serviceCost_fixed_amount: 'Vast bedraag',
          editRestuarant_placeholder_serviceCost_fixed_amount: 'Kost',
          editRestuarant_placeholder_serviceCost_percentage_amount: 'Servicekost percentage',
          editRestuarant_label_delivery_cost: 'Leverkost',
          editRestuarant_placeholder_delivery_cost: 'Geef leverkost in',
          editRestuarant_label_free_delivery_amount: 'Gratis levering boven bepaald bedrag',
          editRestuarant_placeholder_free_delivery_amount: 'Geef gratis leverbedrag',
          editRestuarant_label_pageTitle: 'Page Title',
          editRestuarant_label_metaTitles: 'Meta Titles(comma seperated)',
          editRestuarant_placeholder_metaTitles: 'Enter Meta titles',
          editRestuarant_label_metaDescription: 'Meta Descriptions(comma seperated)',
          editRestuarant_placeholder_metaDescription: 'Enter Meta Descriptions',
          editRestuarant_label_metaKeyword: 'Meta Keywords(comma seperated)',
          editRestuarant_placeholder_metaKeyword: 'Enter meta keywords',
          editRestuarant_label_slug: 'Slug',
          editRestuarant_placeholder_slug: 'Vul slug in',
          editRestuarant_label_max_num_orders: 'Max aantal bestellingen',
          editRestuarant_placeholder_max_num_orders: 'Geef Max aantal bestellingen in',
          editRestuarant_label_per_time_interval: 'Per tijdsinterval (in min)',
          editRestuarant_placeholder_per_time_interval: 'Geef Per tijdsinterval in',
          editRestuarant_label_restuarant_email: 'Email restaurant',
          editRestuarant_placeholder_restuarant_email: 'Vul Email restaurant in',
          editRestuarant_label_telephone: 'Tel restaurant',
          editRestuarant_placeholder_telephone: 'Vul Tel restaurant in',
          editRestuarant_navigate_site: ' bekijk uw URL',
          editRestuarant_successfully_updated: 'Updated Successfully',
          editRestuarant_button_save: 'save',
        },
        Opening_hours: {
          openingHrs_button_add: 'Voeg tijdslot toe',
          openingHrs_table_title: 'Openingsuren',
          openingHrs_table_day: 'Dag',
          openingHrs_table_from: 'Vanaf',
          openingHrs_table_until: 'Tot',
          openingHrs_table_delivery: 'Levering',
          openingHrs_table_takeaway: 'Afhaal',
          openingHrs_table_action: 'Actie',
        },
        Add_Edit_hours: {
          addOpeningHrs_title: 'Voeg tijdslot toe',
          editOpeningHrs_title: 'Pas tijdslot aan',
          addOpeningHrs_label_select_weekday: 'Kies dag van de week',
          openingHrs_weekday_mon: 'Maandag',
          openingHrs_weekday_Tues: 'Dinsdag',
          openingHrs_weekday_Wed: 'Woensdag',
          openingHrs_weekday_Thurs: 'Donderdag',
          openingHrs_weekday_fri: 'Vrijdag',
          openingHrs_weekday_sat: 'Zaterdag',
          openingHrs_weekday_sun: 'Zondag',
          addOpeningHrs_label_from: 'Vanaf',
          addOpeningHrs_placeholder_from: 'Enter Vanaf',
          addOpeningHrs_placeholder_validation: 'Vul dit veld in',
          addOpeningHrs_placeholder_minvalidation_greater_equal:
            'De waarde moet groter zijn dan of gelijk zijn aan het minimum.',
          addOpeningHrs_placeholder_minvalidation_lessthan_equal:
            'De waarde moet kleiner zijn dan of gelijk zijn aan het maximum.',
          addOpeningHrs_label_until: 'Tot',
          addOpeningHrs_placeholder_until: 'Enter Tot',
          addOpeningHrs_label_delivery_option: 'Levering optie',
          addOpeningHrs_option_delivery_on: 'On Levering optie',
          addOpeningHrs_option_delivery_off: 'Off Levering optie',
          addOpeningHrs_label_pickUp_option: 'Afhaal optie',
          addOpeningHrs_option_pickUp_on: 'On Afhaal optie',
          addOpeningHrs_option_pickUp_off: 'Off Afhaal optie',
          addOpeningHrs_button_cancel: 'cancel',
          addOpeningHrs_button_save: 'save',
          editOpeningHrs_successfully_updated_msg: 'Correct opgeslagen',
          addOpeningHrs_successfully_add_msg: 'Toegevoegd',
          addOpeningHrs_successfully_delete_msg: 'Succesvol verwijderd',
          addOpeningHrs_popup_deDelivery_Pickup:
            'Bezorgen/ophalen kan niet tegelijkertijd UIT zijn!',
        },
        Tables_QR_Codes: {
          title: 'General QR Code',
          tableQRcode_title: 'Tafel QR Codes',
          tableQRcode_panel_number: 'Tafelnummer',
          tableQRcode_action: 'Actie',
          tableQRcode_action_button_title: 'Maak qr code',
          tableQRcode_download_QR: 'Download QR',
        },
        Category: {
          category_title: 'Categoriën',
          category_button_add: 'Voeg category toe',
          category_table_header_name: 'Naam',
          category_table_header_Actie: 'Actie',
        },
        Add_Edit_Category: {
          addCategory_title: 'Voeg categorie toe',
          editCategory_title: 'Bewerk categorie',
          addCategory_name: 'Naam',
          addCategory_validation_name_empty: 'Vul naam in',
          addCategory_button_cancel: 'cancel',
          addCategory_button_save: 'Save',
        },
        Product_option_groups: {
          productGroup_title: 'Productoptie groepen',
          productGroup_button_add: 'Voeg groep toe',
          productGroup_table_header_nameOption: 'Naam optie',
          productGroup_table_header_addOptionName: 'Add Option Names',
          productGroup_table_header_activeOptionName: 'Active Option Name',
          productGroup_table_header_availableOptionNames: 'Available Option Names',
          productGroup_table_header_action: 'Actie',
          productGroup_table_createArticalOption: 'Maak opties aan',
        },
        Add_Product_option: {
          addProductGroup_title: 'Add New Options Group',
          addProductGroup_label_name: 'Article Option Name:',
          addProductGroup_placeholder_name: 'Enter Name',
          addProductGroup_validation_name_empty: 'please enter name',
          addProductGroup_button_cancel: 'cancel',
          addProductGroup_button_save: 'save',
        },
        Edit_Product_option: {
          editProductGroup_title: 'Edit Options Group',
          editProductGroup_validation_name_empty: 'please enter name',
          editProductGroup_label_selectArtical: 'Select Article Options',
          editProductGroup_label_minvalue: 'Enter Min value',
          editProductGroup_label_maxValue: 'Enter Max value',
          editProductGroup_button_cancel: 'cancel',
          editProductGroup_button_save: 'save',
        },
        Create_Article_Options: {
          createArticalOption_title: 'Maak artikeloptie aan',
          createArticalOption_alias: 'Alias',
          createArticalOption_validation_alias_empty: 'Geef alias in',
          createArticalOption_label_choice: 'Keuze',
          createArticalOption_label_price: 'Prijs',
          createArticalOption_label_minSelection: 'Min Selection',
          createArticalOption_label_maxSelection: 'Max Selection',
          createArticalOption_button_submit: 'Verstuur',
        },
        Article_Options_table: {
          articalOption_title: 'Beheer productopties',
          articalOption_table_header_options: 'Optie',
          articalOption_table_header_choiceName: 'Keuze optie',
          articalOption_table_header_choicePrice: 'Prijs optie',
          articalOption_table_header_optionName: 'Naam optie',
          articalOption_table_header_action: 'Actie',
        },
        Edit_Article_Options_table: {
          editArticalOption_title: 'Pas optie aan',
          editArticalOption_label_choice: 'Keuze',
          editArticalOption_placeholder_choice: 'Geef keuze in',
          editArticalOption_label_price: 'Prijs',
          editArticalOption_button_submit: 'submit',
          editArticalOption_button_cancel: 'cancel',
        },
        Manage_products: {
          manageProduct_title: 'Beheer producten',
          manageProduct_button: 'Voeg product toe',
          manageProduct_table_header_productName: 'Naam product',
          manageProduct_table_header_price: 'Prijs',
          manageProduct_table_header_category: 'Categorie',
          manageProduct_table_header_pickup_delivery: 'BTW % bij pickup/levering',
          manageProduct_table_header_spot: 'BTW % bij ter plaatse',
          manageProduct_table_header_moreInfo: 'Meer informatie',
          manageProduct_table_header_ingredients: 'Ingredienten',
          manageProduct_table_header_image: 'Afbeelding',
          manageProduct_table_header_action: 'Actie',
        },
        Add_Product: {
          addProduct_title: 'Voeg product toe',
          addProduct_label_image: 'Add Article Image',
          addProduct_label_name: 'Naam product:',
          addProduct_placeholder_name: 'Enter Name',
          addProduct_validation_name_empty: 'please enter Name',
          addProduct_label_price: 'Prijs',
          addProduct_placeholder_price: 'Vul prijs in',
          addProduct_label_category: 'Categorie',
          addProduct_dropdown_category: 'Selecteer Categorie',
          addProduct_label_pickup_delivery: 'BTW % bij pickup/levering',
          addProduct_placeholder_pickup_delivery: 'BTW % in percentage',
          addProduct_label_spot: 'BTW % bij ter plaatse',
          addProduct_placeholder_spot: 'BTW % in percentage',
          addProduct_label_productOption: 'Productopties:',
          addProduct_dropdown_productOption: 'Selecteer productopties',
          addProduct_label_ingredients: 'Voeg Ingredienten toe (comma seprated):',
          addProduct_label_productSoldOut: 'Product uitverkocht',
          addProduct_label_moreInfo: 'Meer informatie:',
          addProduct_placeholder_moreInfo: 'information',
          addProduct_button_cancel: 'cancel',
          addProduct_button_submit: 'Submit',
        },
        CSV_Upload: {
          csvUpload_title: 'Upload CSV',
          csvUpload_label_csvFile: ' Upload CSV file Here',
          csvUpload_button_save: 'save',
          csvUpload_success_msg: 'File Submit',
          csvUpload_validation_file_extention: 'File Type invalid',
        },
        Orders: {
          orders_title: 'Overzicht bestellingen',
          orders_filter_startDate: 'Startdatum',
          orders_filter_endDate: 'Einddatum',
          orders_filter_status_pending: 'pending',
          orders_filter_status_accepted: 'accepted',
          orders_filter_status_rejected: 'rejected',
          orders_button_filter: 'Filter',
          orders_button_downloadCsv: 'Download CSV',
          orders_button_downloadXlsx: 'Download XLSX',
          orders_button_Downloadartikellijst: 'Download artikellijst',
          order_tabel_header_orderid: 'Order ID',
          order_tabel_header_orderStatus: 'Status bestelling',
          order_tabel_header_paymentStatus: 'Betalingstatus',
          order_tabel_header_panelNumber: 'Tafelnummer',
          order_tabel_header_customerNote: 'Opmerking klant',
          order_tabel_header_customerName: 'Naam klant',
          order_tabel_header_Email: 'E-mail',
          order_tabel_header_phoneNumber: 'GSM',
          order_tabel_header_address: 'Adres',
          // order_tabel_header_btwPercentage: 'BTW Percentage',
          order_tabel_header_amount: 'Bedrag',
          order_tabel_header_forWhen: 'Voor wanneer',
          order_tabel_header_order: 'Bestelling',
        },
        GeneralAgreements : {
          General_Agreements_title:'Algemene overeenkomsten',
          GeneralAgreements_label_BTW: 'BTW Nummer',
          GeneralAgreements_label_Naam: 'Naam Vennootschap',
          GeneralAgreements_email: "Email",
          GeneralAgreements_label_Adres:'Adres Vennootschap',
        },
        customer: {
          title: 'Klant',
          exportButtonLabel: 'Exporteren',
          table: {
            name: 'Naam',
            contactDetails: 'Contact details',
            totalOrderValue: 'Totale bestelwaarde',
            createdAt: 'Datum gecreeërd',
            totalRecords: 'Totaal aantal records',
            noDataFound: "Er zijn tot nu toe geen gegevens gevonden.",
            address: 'adres',
            phone: 'Telefoon'
          }

        },
        change_password: {
          changePassword_title: 'Pas wachtwoord aan',
          changePassword_currentPassword: 'Huidig wachtwoord',
          changePassword_newPassword: 'Nieuw wachtwoord',
          changePassword_submit: 'Verstuur',
        },
        sidebar: {
          sidebar_editRestuarant: 'Bewerk restaurant',
          sidebar_create_qr_code: 'Maak unieke qr codes aan',
          sidebar_category: 'Categoriën',
          sidebar_product_option_group: 'Productoptie groepen',
          sidebar_manage_product_options: 'Beheer productopties',
          sidebar_manage_product: 'Beheer producten',
          sidebar_csvUpload: 'CSV Upload',
          sidebar_orders: 'Bestellingen',
          sidebar_change_password: 'Pas wachtwoord aan',
          sidebar_General_Agreements:'Algemene overeenkomsten',
          sidebar_logout: 'Logout',
          sidebar_customer: 'Klant',
        },
      },
    },
    French: {
      translation: {
        Login: {
          login_title: 'Panneau de restaurant',
          login_subtitle: 'Se connecter',
          login_placeholder_email: 'E-mail',
          login_placeholder_password: 'mot de passe',
          login_validation_email_empty: 'Adresse e-mail',
          login_validation_email_not_valid: 'Entrez une adresse e-mail correcte',
          login_validation_password_empty: 'Entrez votre mot de passe',
          login_validation_email_password_wrong: "L' e-mail ou le mot de passe est faux",
          login_navigate_forget_password: 'Vous avez oublié votre mot de passe?',
          login_button: 'Se connecter',
        },
        Forget_Password: {
          forgetPassword_title: 'Panneau de restaurant',
          forgetPassword_label_email:
            'Entrez votre adresse e-mail et recevez un nouveau mot de passe',
          forgetPassword_email_not_exists: "Le courrier électronique n'existe pas",
          forgetPassword_success_mailSend_msg:
            'Nous avons envoyé un e-mail pour restaurer votre mot de passe.',
          forgetPassword_submit: 'Envoyer',
        },
        GeneralAgreements : {
          General_Agreements_title:"Accords généraux",
          GeneralAgreements_label_BTW: 'BTW Nummer',
          GeneralAgreements_label_Naam: 'Naam Vennootschap',
          GeneralAgreements_email: "Email",
          GeneralAgreements_label_Adres:'Adres Vennootschap',
        },
        Bewerk_restaurant: {
          editRestuarant_title: 'Modifier le restaurant',
          editRestuarant_label_name: 'Nom de restaurant',
          editRestuarant_placeholder_name: 'Nom de restaurant',
          editRestuarant_label_address: 'lieu',
          editRestuarant_placeholder_address: 'Emplacement du restaurant',
          editRestuarant_label_availability_zipCodes: 'adress de livraison : (séparée par une virgule)',
          editRestuarant_placeholder_availability_zipCodes: 'adress de livraison : (séparée par une virgule)',
          editRestuarant_label_postCode: 'Code postal',
          editRestuarant_placeholder_postCode: 'Code postal du restaurant',
          editRestuarant_label_benner: 'Bannière',
          editRestuarant_Choose_File_benner: 'Choisissez la bannière',
          editRestuarant_banner_preview: 'Image précédente',
          editRestuarant_label_logo: 'Mettre à jour le logo',
          editRestuarant_Choose_file_logo: 'Choisissez le logo',
          editRestuarant_logo_preview: 'Image précédente',
          editRestuarant_label_numberOfTables: 'Nombre de tables avec un code QR unique',
          editRestuarant_placeholder_numberOfTables: 'Aucun numéro dans',
          editRestuarant_label_staus: 'situation du restaurant',
          editRestuarant_label_visible_on_webapp: 'visible sur le web/app',
          editRestuarant_label_preparation_time: 'Temps de préparation en min',
          editRestuarant_placeholder_preparation_time: 'Aucun numéro dans',
          editRestuarant_label_msg_restuarant_close: "Message si fermé",
          editRestuarant_label_europaBankUID: 'Europabank uid',
          editRestuarant_placeholder_europaBankUID: 'Entrez UID',
          editRestuarant_label_europaBank_client_secret: 'Secret client Europabank',
          editRestuarant_placeholder_europaBank_client_secret: 'Entrez client secret',
          editRestuarant_label_europaBank_server_secret: 'Secret du serveur Europabank',
          editRestuarant_placeholder_europaBank_server_secret: 'Entrez server secret',
          editRestuarant_label_europaBank_url: 'URL de europabank',
          editRestuarant_placeholder_europaBank_url: "ENTRER L'URL",
          editRestuarant_label_pay_online: 'Payer en ligne?',
          editRestuarant_option_pay_online_on: 'On',
          editRestuarant_option_pay_online_off: 'Off',
          editRestuarant_placeholder_payOnSpot: 'Payer sur place?',
          editRestuarant_option_payOnsite_on: 'On',
          editRestuarant_option_payOnsite_Off: 'Off',
          editRestuarant_label_serviceRequest_at_table: 'Demander service à la table',
          editRestuarant_option_serviceRequest_on: 'On',
          editRestuarant_option_serviceRequest_off: 'Off',
          editRestuarant_label_billRequest_at_table: "L'addition à la table",
          editRestuarant_option_billRequest_at_table_on: 'On',
          editRestuarant_option_billRequest_at_table_off: 'Off',
          editRestuarant_label_active_sms: 'Activer la fonction SMS',
          editRestuarant_label_active_sms_on: 'On',
          editRestuarant_label_active_sms_off: 'Off',
          editRestuarant_label_serviceCostType: 'Type de frais de service',
          editRestuarant_option_serviceCostType_fixed_amount: 'Montant fixe',
          editRestuarant_option_serviceCostType_percentage: 'Pourcentage',
          editRestuarant_label_serviceCost_fixed_amount: "Montant fixe",
          editRestuarant_placeholder_serviceCost_fixed_amount: 'Coût',
          editRestuarant_placeholder_serviceCost_percentage_amount: 'Pourcentage de frais de service',
          editRestuarant_label_delivery_cost: 'Frais de livraison',
          editRestuarant_placeholder_delivery_cost: 'Entrez le frais de livraison',
          editRestuarant_label_free_delivery_amount: "Livraison gratuite au-dessus d'un certain montant",
          editRestuarant_placeholder_free_delivery_amount: 'Donner un montant hépatique gratuit',
          editRestuarant_label_pageTitle: 'Titre de la page',
          editRestuarant_label_metaTitles: 'Meta titres (virgule séparée)',
          editRestuarant_placeholder_metaTitles: 'Entrez les méta-titres',
          editRestuarant_label_metaDescription: 'Meta descriptions (virgule séparée)',
          editRestuarant_placeholder_metaDescription: 'Entrez les méta descriptions',
          editRestuarant_label_metaKeyword: 'Meta Mots-clés (virgule séparée)',
          editRestuarant_placeholder_metaKeyword: 'Entrez les mots clés Meta',
          editRestuarant_label_slug: 'slug',
          editRestuarant_placeholder_slug: 'Remplissez slug',
          editRestuarant_label_max_num_orders: 'Nombre maximum de commandes',
          editRestuarant_placeholder_max_num_orders: 'Entrez nombre maximum des commandes',
          editRestuarant_label_per_time_interval: 'Par intervalle de temps (en min)',
          editRestuarant_placeholder_per_time_interval: 'Entrez par intervalle de temps',
          editRestuarant_label_restuarant_email: 'E-mail',
          editRestuarant_placeholder_restuarant_email: "Remplissez l' e-mail du restaurant",
          editRestuarant_label_telephone: 'Numéro tel du restaurant',
          editRestuarant_placeholder_telephone: 'Remplissez numéro tel du restaurant',
          editRestuarant_navigate_site: ' Consultez votre URL',
          editRestuarant_successfully_updated: 'Mis à jour avec succès',
          editRestuarant_button_save: 'sauvegarder',
        },
        Opening_hours: {
          openingHrs_button_add: 'Ajouter un horaire de travail',
          openingHrs_table_title: "Heures d 'ouverture",
          openingHrs_table_day: 'Jour',
          openingHrs_table_from: 'À partir de',
          openingHrs_table_until: "Jusqu'à",
          openingHrs_table_delivery: 'Livraison',
          openingHrs_table_takeaway: 'Emporter',
          openingHrs_table_action: 'Action',
        },
        Add_Edit_hours: {
          addOpeningHrs_title: 'Ajouter un horaire de travail',
          editOpeningHrs_title: "Modifier un horaire de travail",
          addOpeningHrs_label_select_weekday: 'Choisissez le jour de la semaine',
          openingHrs_weekday_mon: 'Lundi',
          openingHrs_weekday_Tues: 'Mardi',
          openingHrs_weekday_Wed: 'Mercredi',
          openingHrs_weekday_Thurs: 'Jeudi',
          openingHrs_weekday_fri: 'Vendredi',
          openingHrs_weekday_sat: 'Samedi',
          openingHrs_weekday_sun: 'Dimanche',
          addOpeningHrs_label_from: 'À partir de',
          addOpeningHrs_placeholder_from: 'À partir de',
          addOpeningHrs_placeholder_validation: 'Merci de remplir ce champ',
          addOpeningHrs_placeholder_minvalidation_greater_equal: 'La valeur doit être supérieure ou égale au minimum',
          addOpeningHrs_placeholder_minvalidation_lessthan_equal: 'La valeur doit être inférieure ou égale au maximum',
          addOpeningHrs_label_until: "Jusqu'à",
          addOpeningHrs_placeholder_until: 'Entrer à',
          addOpeningHrs_label_delivery_option: 'Option de livraison',
          addOpeningHrs_option_delivery: 'Option de livraison on',
          addOpeningHrs_option_delivery_on: 'Option de livraison on',
          addOpeningHrs_option_delivery_off: 'Option de livraison off',
          addOpeningHrs_label_pickUp_option: 'option à emporter',
          addOpeningHrs_option_pickUp_on: 'option à emporter "on"',
          addOpeningHrs_option_pickUp_off: 'option à emporter "off"',
          addOpeningHrs_button_cancel: 'annuler',
          addOpeningHrs_button_save: 'sauvegarder',
          editOpeningHrs_successfully_updated_msg: 'sauvegarder correctement',
          addOpeningHrs_successfully_add_msg: 'Ajoutée',
          addOpeningHrs_successfully_delete_msg: 'Supprimé avec succès',
          addOpeningHrs_popup_deDelivery_Pickup: 'La livraison/retrait ne peut pas être désactivée en même temps !',
        },
        Tables_QR_Codes: {
          tableQRcode_title: 'Codes QR de la table',
          tableQRcode_panel_number: 'Numéro de la table',
          tableQRcode_action: 'Action',
          tableQRcode_action_button_title: 'Créer code QR',
          tableQRcode_download_QR: 'Télécharger code QR',
        },
        Category: {
          category_title: 'Catégorie',
          category_button_add: 'Ajouter une catégorie',
          category_table_header_name: 'Nom',
          category_table_header_Actie: 'Action',
        },
        Add_Edit_Category: {
          addCategory_title: 'Ajouter une catégorie',
          editCategory_title: 'Modifier une catégorie',
          addCategory_name: 'Nom',
          addCategory_validation_name_empty: 'Remplissez le nom',
          addCategory_button_cancel: 'cancel',
          addCategory_button_save: 'Sauvegarder',
        },
        Product_option_groups: {
          productGroup_title: "Groupes d'option noms",
          productGroup_button_add: 'Ajouter un groupe',
          productGroup_table_header_nameOption: 'Option nom',
          productGroup_table_header_addOptionName: "Ajouter option nom",
          productGroup_table_header_activeOptionName: "option noms activés",
          productGroup_table_header_availableOptionNames: "Option nom disponibles",
          productGroup_table_header_action: 'action',
          productGroup_table_createArticalOption: "Créez des options d'article",
        },
        Add_Product_option: {
          addProductGroup_title: "Ajouter un nouveau groupe d'options",
          addProductGroup_label_name: "Nom de l'option d'article:",
          addProductGroup_placeholder_name: 'Saisir le nom',
          addProductGroup_validation_name_empty: 'Entrez le nom',
          addProductGroup_button_cancel: 'annuler',
          addProductGroup_button_save: 'sauvegarder',
        },
        Edit_Product_option: {
          editProductGroup_title: 'Modifier Group Options Group pk',
          editProductGroup_validation_name_empty: 'Veuillez saisir le nom',
          editProductGroup_label_selectArtical: "Sélectionnez les options d'article",
          editProductGroup_label_minvalue: 'Saisir la valeur minimale',
          editProductGroup_label_maxValue: 'Saisir la valeur maximale',
          editProductGroup_button_cancel: 'annuler',
          editProductGroup_button_save: 'sauvegarder',
        },
        Create_Article_Options: {
          createArticalOption_title: "Créez une option d'article",
          createArticalOption_alias: 'pseudo',
          createArticalOption_validation_alias_empty: 'Donnez un pseudo',
          createArticalOption_label_choice: 'Choix',
          createArticalOption_label_price: 'Prix',
          createArticalOption_label_minSelection: 'Sélection minimale',
          createArticalOption_label_maxSelection: 'Sélection maximale',
          createArticalOption_button_submit: 'Envoyer',
        },
        Article_Options_table: {
          articalOption_title: 'Gérer les options de produit',
          articalOption_table_header_options: 'Option',
          articalOption_table_header_choiceName: 'Option de choix',
          articalOption_table_header_choicePrice: 'Option de prix',
          articalOption_table_header_optionName: 'Option de noms',
          articalOption_table_header_action: 'Action',
        },
        Edit_Article_Options_table: {
          editArticalOption_title: "Modifier l'option",
          editArticalOption_label_choice: 'Choix',
          editArticalOption_placeholder_choice: 'Donner un choix',
          editArticalOption_label_price: 'Prix',
          editArticalOption_button_submit: 'soumettre',
          editArticalOption_button_cancel: 'annuler ',
        },
        Manage_products: {
          manageProduct_title: 'gestion des produits',
          manageProduct_button: 'Ajouter le produit',
          manageProduct_table_header_productName: 'Nom du produit',
          manageProduct_table_header_price: 'Prix',
          manageProduct_table_header_category: 'Catégorie',
          manageProduct_table_header_pickup_delivery: 'TVA% avec ramassage / livraison',
          manageProduct_table_header_spot: 'TVA% sur le site',
          manageProduct_table_header_moreInfo: "Plus d'information",
          manageProduct_table_header_ingredients: 'Ingrédients',
          manageProduct_table_header_image: 'Image',
          manageProduct_table_header_action: 'Action',
        },
        Add_Product: {
          addProduct_title: 'Ajouter le produit',
          addProduct_label_image: "Ajouter l'image de l'article",
          addProduct_label_name: 'Nom Produit:',
          addProduct_placeholder_name: 'Saisir le nom',
          addProduct_validation_name_empty: 'Veuillez saisir le nom',
          addProduct_label_price: 'Prix',
          addProduct_placeholder_price: 'Remplissez le prix',
          addProduct_label_category: 'Catégorie',
          addProduct_dropdown_category: 'Choisir une catégorie',
          addProduct_label_pickup_delivery: 'TVA% avec ramassage / livraison',
          addProduct_placeholder_pickup_delivery: 'TVA% en pourcentage',
          addProduct_label_spot: 'TVA% sur le site',
          addProduct_placeholder_spot: 'TVA% en pourcentage',
          addProduct_label_productOption: 'Options du produit:',
          addProduct_dropdown_productOption: 'Sélectionner les options de produit',
          addProduct_label_ingredients: 'Ajouter des ingrédients (virgule séparée):',
          addProduct_label_productSoldOut: 'Produit en rupture de stock',
          addProduct_label_moreInfo: "Plus d'information:",
          addProduct_placeholder_moreInfo: 'information',
          addProduct_button_cancel: 'annuler',
          addProduct_button_submit: 'Soumettre',
        },
        CSV_Upload: {
          csvUpload_title: 'Télécharger CSV',
          csvUpload_label_csvFile: ' Télécharger le fichier CSV ici',
          csvUpload_button_save: 'sauvegarder',
          csvUpload_success_msg: 'Soumettez le fichier',
          csvUpload_validation_file_extention: 'Type de fichier non valide',
        },
        Orders: {
          orders_title: 'Aperçu des commandes',
          orders_filter_startDate: 'Date de début',
          orders_filter_endDate: 'Date de fin',
          orders_filter_status_pending: 'en attente',
          orders_filter_status_accepted: 'accepté',
          orders_filter_status_rejected: 'rejeté',
          orders_button_filter: 'Filtre',
          orders_button_downloadCsv: 'Télécharger CSV',
          orders_button_downloadXlsx:'Télécharger XLSX',
          orders_button_Downloadartikellijst: 'Download liste des articles',
          order_tabel_header_orderid: 'numéro de commande',
          order_tabel_header_orderStatus: "L'état du commande",
          order_tabel_header_paymentStatus: "L'état de paiement",
          order_tabel_header_panelNumber: "Numéro de la table",
          order_tabel_header_customerNote: 'Remarques du client',
          order_tabel_header_customerName: 'Nom du client',
          order_tabel_header_Email: 'E-mail',
          order_tabel_header_phoneNumber: 'Mobile',
          order_tabel_header_address: 'Adresse',
          // order_tabel_header_btwPercentage: 'BTW Percentage',
          order_tabel_header_amount: 'Montant',
          order_tabel_header_forWhen: 'Pour quand',
          order_tabel_header_order: 'Commande',
        },
        customer: {
          title: 'Cliente',
          exportButtonLabel: 'Exporter',
          table: {
            name: 'Nom',
            contactDetails: 'Détails du contact',
            totalOrderValue: 'Valeur totale de la commande',
            createdAt: 'date créée',
            totalRecords: 'Total des enregistrements',
            noDataFound: "Aucune donnée trouvée pour l'instant.",
            address: 'adresse',
            phone: 'téléphone'
          }
        },
        change_password: {
          changePassword_title: 'Mot de passe',
          changePassword_currentPassword: 'Mot de passe actuel',
          changePassword_newPassword: 'Nouveau mot de passe',
          changePassword_submit: 'Envoyer',
        },
        sidebar: {
          sidebar_editRestuarant: 'Modifier le restaurant',
          sidebar_create_qr_code: 'Créer des codes QR uniques',
          sidebar_category: 'Catégories',
          sidebar_product_option_group: "Groupes d'options de produit",
          sidebar_manage_product_options: 'Gérer les options de produit',
          sidebar_manage_product: 'Produits de gestion',
          sidebar_csvUpload: 'Téléchargement de CSV',
          sidebar_orders: 'Ordres',
          sidebar_change_password: 'Mot de passe',
          sidebar_General_Agreements:'Accords généraux',
          sidebar_logout: 'Se déconnecter',
          sidebar_customer: 'Client',
        },
      },
    },
  },
  lng: 'Dutch',
  fallbackLng: 'Dutch',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  initImmediate: false,
};

export const languageAliases = {
  English: 'en',
  Dutch: 'nl',
  French: 'fr',
};
