import React from 'react'
import Sidebar from '../Sidebar'
import Header from '../Header'

type Props = {
    children: React.ReactNode
}

const Layout = ({ children }: Props) => {
    return (
        <>
            <Sidebar />
            <div id="border-top-radius" className="container-fluid main-panel" >
                <Header />
                <div className="row mt-5 pt-5">
                    <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout