import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// @ts-ignore
function PrivateRoute({ component: Component, ...rest }) {
  const jwt = localStorage.getItem('jwt');
  return (
    <Route
      {...rest}
      render={(props) =>
        jwt ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
        )
      }
    />
  );
}

export default PrivateRoute;
