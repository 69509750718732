import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import store from './store';

import App from './App';
import 'typeface-roboto';
import './index.css';
import './assets/css/paper-dashboard.css';
import './assets/css/demo.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import axios from 'axios';

axios.interceptors.request.use((config) => {
  let lang = localStorage.getItem('yopla_lang') === 'French' ? 'fr' : 'nl';
  if (
    config.url?.includes('/restaurant/api/login/') ||
    config.url?.includes('/restaurant/forget-password/')
  )
    return {
      ...config,
      headers: { ...config.headers, 'Accept-Language': lang }
    };

  if (!config.headers.common.Autorization && localStorage.getItem('jwt')) {
    config.headers.common.Authorization = `JWT ${localStorage.getItem('jwt')}`;
  }

  return {
    ...config,
    headers: { ...config.headers, 'Accept-Language': lang }
  }
});
ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
