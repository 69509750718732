import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './style.css';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from './index';
import { API } from '../api';
import { getCategories, getArticles } from '../store/actions';
import { useTranslation } from 'react-i18next';

let isSold: any = false;

const EditArticleModel: FC<any> = (props) => {
  const { t } = useTranslation();
  const [articleOption, setArticleOption] = useState<any>('');
  const [articleOptionid, setArticleOptionid] = useState<any>('');
  const [optionname, setoptionname] = useState<any>('');
  const [selectedCategory, setselectedCategory] = useState<any>(null);
  const [selectedCategoryName, setselectedCategoryName] = useState<any>('');
  const [multipaloptions, setmultipaloptions] = useState<any>([]);
  const [isSoldOut, setSoldOut] = useState(false);
  const [combinedingredients, setcombinedingredients] = useState<any>('');
  const [finalcombinedingredients, setfinalcombinedingredients] = useState('');
  const newArray = [] as any;

  const id = localStorage.getItem('rest_id') as string;

  const categorylist = useSelector(({ categories }: StoreRoot) => categories);

  const dispatch = useDispatch();

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  useEffect(() => {
    try {
      if (props.article) {
        const data = props.article.ingredient;
        const combined = data.map((el: any) => el.name).join(',');
        setcombinedingredients(combined);
      }
    } catch (err) {
      setcombinedingredients('');
    }

    dispatch(getCategories(id));
    if (!props.article) {
      setArticleOption([]);
    } else {
      setArticleOption(props.article);
      setArticleOptionid(props.articleId);
      if (props.articleId === 'new') {
        setselectedCategoryName('');
        setselectedCategory(null);
        setmultipaloptions([]);
      } else if (props.article) {
        if (props.article?.category?.name) {
          setselectedCategoryName(props.article.category.name);
          const category = {
            value: props.article.category.name,
            label: props.article.category.name,
          };
          setselectedCategory(category);
        } else {
          setselectedCategory(null);
        }
        setSoldOut(props.article.sold_out);
      }
    }
  }, [props]);

  useEffect(() => {
    if (props.modal) {
      setTimeout(() => {
        let name = document.getElementById('naamValidation') as HTMLInputElement;
        name.addEventListener('input', function (e) {
          name.setCustomValidity(''); //remove message when new text is input
        });
        name.addEventListener('invalid', function (e) {
          name.setCustomValidity(t('Add_Product.addProduct_validation_name_empty')); //custom validation message for invalid text
        });

        let namePrice = document.getElementById('pricevalidation') as HTMLInputElement;
        namePrice.addEventListener('input', function (e) {
          namePrice.setCustomValidity(''); //remove message when new text is input
        });
        namePrice.addEventListener('invalid', function (e) {
          namePrice.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
        });

        let namebtw = document.getElementById('btwvalidation') as HTMLInputElement;
        namebtw.addEventListener('input', function (e) {
          namebtw.setCustomValidity(''); //remove message when new text is input
        });
        namebtw.addEventListener('invalid', function (e) {
          namebtw.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
        });

        let namebij = document.getElementById('bijvalidation') as HTMLInputElement;
        namebij.addEventListener('input', function (e) {
          namebij.setCustomValidity(''); //remove message when new text is input
        });
        namebij.addEventListener('invalid', function (e) {
          namebij.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
        });
      }, 100);
    }
  }, [props.modal]);

  const getArticleOptionName = async () => {
    await API.restaurantApi.getArticleOptionNameModified(id as string).then((res) => {
      setoptionname(res.data);
    });
  };

  const getPostDataForArticle = async () => {
    await API.restaurantApi.getPostDataForArticle(props.articleId as string).then((response) => {
      if (response.data.length > 0) {
        if (response.data && response.data[0].Articloption) {
          console.log('just data', response.data);
          response.data.forEach((element: any) => {
            if (element && element.Articloption) {
              const newObj = {
                value: element.Articloption.label,
                label: element.Articloption.label,
              };
              newArray.push(newObj);
              console.log('New Array', newArray);
              // setmultipaloptions(newArray);
            }
          });

          setmultipaloptions(newArray);
          console.log(`final array${newArray}`);
        }
      }
    });
  };

  useEffect(() => {
    getArticleOptionName();
    if (props.articleId > 0) {
      setmultipaloptions([]);
      getPostDataForArticle();
    }
  }, [props, props.articleId]);

  const handleChangeCategory = (e: any) => {
    const category = [{ value: e.value, label: e.value }];
    setselectedCategoryName(e.value);
    setselectedCategory(category);
  };

  const changearticledata = (e: any) => {
    setArticleOption({
      ...articleOption,
      [e.target.name]: [e.target.value],
    });
  };

  const changeingredientdata = (e: any) => {
    setcombinedingredients(e.target.value);
    setfinalcombinedingredients(e.target.value);
  };

  const refresh = (e: any) => {
    e.preventDefault();
    props.setModal(!props.modal);
    setmultipaloptions([]);
  };

  const changeOptionName = (e: any) => {
    if (e === null) {
      setmultipaloptions([]);
    } else {
      setmultipaloptions(e);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const articleid = articleOptionid;
    const restid = localStorage.getItem('rest_id') as string;
    const ingredient = finalcombinedingredients;
    const ingredients = e.target.elements?.ingredient?.value;
    const articlename = e.target.elements?.articlename?.value;
    const price = e.target.elements?.price?.value || 0;
    const category = selectedCategoryName;
    const pickuptax = e.target.elements?.pickuptax?.value;
    const dineintax = e.target.elements?.dineintax?.value;
    const description = e.target.elements?.description?.value || '';
    const image = e.target.elements?.image?.files[0] || undefined;
    console.log('tsrts', e.target.elements?.image?.files[0]);

    const submitoptionname: any = [];

    if (multipaloptions.length > 0) {
      multipaloptions.forEach((item: any) => {
        submitoptionname.push(item.value);
      });
    }

    const formData = new FormData();

    formData.append('articleid', articleid);
    formData.append('restid', restid);
    formData.append('articlename', articlename);
    formData.append('price', price);
    formData.append('category', category);
    formData.append('pickuptax', pickuptax);
    formData.append('dineintax', dineintax);
    formData.append('description', description);
    formData.append('ingredient', ingredient);
    formData.append('sold_out', isSold);
    if (submitoptionname.length > 0) {
      formData.append('optionNames', submitoptionname);
    }

    if (image !== undefined) {
      formData.append('image', image);
    }

    if (articleOptionid === 'new') {
      await API.restaurantApi.createArticle(formData, restid).then((response) => {
        toast.success('Added Successfully!', { containerId: 'B' });
        asyncDispatch(getArticles(id)).then(() => {
          props.setModal(!props.modal);
        });
        setselectedCategory(null);
        setmultipaloptions([]);
      });
    } else {
      await API.restaurantApi.updateArticle(formData, articleOptionid, restid).then((response) => {
        toast.success(t('Add_Edit_hours.editOpeningHrs_successfully_updated_msg'), { containerId: 'B' });
        asyncDispatch(getArticles(id)).then(() => {
          props.setModal(!props.modal);
        });
        setselectedCategory(null);
        setmultipaloptions([]);
      });
    }
  };

  isSold = isSoldOut;

  return (
    <Modal className="modal-lg" isOpen={props.modal} toggle={(e: any) => props.toggle(e)}>
      {articleOption && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <ModalHeader toggle={(e: any) => refresh(e)}>
            {articleOptionid === 'new' ? t('Add_Product.addProduct_title') : 'Beheer producten'}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <h6 className="w-100 text-center">
                {articleOptionid === 'new'
                  ? t('Add_Product.addProduct_label_image')
                  : 'Update Article Image'}
              </h6>

              <div className="input-group custom-file-button w-100 text-center ml-5 d-flex justify-content-center">

                <div className='w-50 d-flex'>
                  <label className="input-group-text" htmlFor="group_image">
                    {t('Bewerk_restaurant.editRestuarant_Choose_File_benner')}
                  </label>

                  <input
                    style={{ marginLeft: 30 }}
                    type="file"
                    className="form-control filetype"
                    onChange={(e) => changearticledata(e)}
                    id="group_image"
                    name='image'
                    accept="image/png, image/gif, image/jpeg,image/jpg, image/webp"
                  />
                </div>

              </div>


              {/* <div className="w-100 text-center ml-5">
                <input
                  style={{ marginLeft: 30 }}
                  name="image"
                  type="file"
                  onChange={(e) => changearticledata(e)}
                  className="filetype"
                  id="group_image"
                />
              </div> */}
              <div className="col-sm-4 mt-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>
                      {/* Naam product: */}
                      {t('Add_Product.addProduct_label_name')}
                    </b>
                  </label>
                  <InputField
                    className="form-control"
                    id="naamValidation"
                    name="articlename"
                    value={articleOption ? articleOption.articlename || '' : ''}
                    onChange={(e: any) => changearticledata(e)}
                    placeholder={t('Add_Product.addProduct_placeholder_name')}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>
                      {/* Prijs: */}
                      {t('Add_Product.addProduct_label_price')}
                    </b>
                  </label>
                  <InputField
                    className="form-control"
                    id="pricevalidation"
                    name="price"
                    value={articleOption ? articleOption.price : ''}
                    onChange={(e: any) => changearticledata(e)}
                    placeholder={t('Add_Product.addProduct_placeholder_price')}
                    required
                    type="number"
                  />
                </div>
              </div>
              <div className="col-sm-4 mt-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>
                      {/* Categorie: */}
                      {t('Add_Product.addProduct_label_category')}
                    </b>
                  </label>
                  <Select
                    name="category"
                    defaultValue={selectedCategory}
                    options={categorylist}
                    value={selectedCategory}
                    onChange={(e) => handleChangeCategory(e)}
                    placeholder={t('Add_Product.addProduct_dropdown_category')}
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>
                      {/* BTW % bij pickup/levering: */}
                      {t('Add_Product.addProduct_label_pickup_delivery')}
                    </b>
                  </label>
                  <select
                    className="form-control"
                    id="btwvalidation"
                    name="pickuptax"
                    value={articleOption ? articleOption.pickuptax || '' : ''}
                    onChange={(e: any) => changearticledata(e)}
                    // placeholder={t('Add_Product.addProduct_placeholder_pickup_delivery')}
                  >
                    <option value="" disabled hidden>
                    {t('Add_Product.addProduct_placeholder_pickup_delivery')}
                     </option>
                     <option value="0">0%</option>
                      <option value="6">6%</option>
                      <option value="12">12%</option>
                      <option value="21">21%</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>
                      {/* BTW % bij ter plaatse: */}
                      {t('Add_Product.addProduct_label_spot')}
                    </b>
                  </label>
                  <select
                    className="form-control"
                    id="bijvalidation"
                    name="dineintax"
                    value={articleOption ? articleOption.dineintax || '' : ''}
                    // placeholder={t('Add_Product.addProduct_placeholder_spot')}
                    onChange={(e: any) => changearticledata(e)}
                  >
                     <option value="" disabled hidden>
                    {t('Add_Product.addProduct_placeholder_spot')}
                     </option>
                     <option value="0">0%</option>
                      <option value="6">6%</option>
                      <option value="12">12%</option>
                      <option value="21">21%</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>
                      {/* Product Options: */}
                      {t('Add_Product.addProduct_label_productOption')}
                    </b>
                  </label>
                  <Select
                    // defaultValue={multipaloptions ? multipaloptions : ''}
                    name="optionnames"
                    options={optionname}
                    value={multipaloptions}
                    onChange={(e) => changeOptionName(e)}
                    isMulti={true}
                    placeholder={t('Add_Product.addProduct_dropdown_productOption')}
                    required
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>
                      {/* Voeg Ingredienten toe (comma seprated): */}
                      {t('Add_Product.addProduct_label_ingredients')}
                    </b>
                  </label>
                  <InputField
                    className="form-control"
                    name="ingredient"
                    id="ingredient"
                    onChange={(e: any) => changeingredientdata(e)}
                    value={articleOption ? combinedingredients : ''}
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>
                      {/* Product Sold Out: */}
                      {t('Add_Product.addProduct_label_productSoldOut')}
                    </b>
                  </label>
                  <div>
                    <input
                      type="radio"
                      name="sold"
                      value="Yes"
                      checked={!!isSoldOut}
                      style={{
                        marginTop: '12px',
                        marginLeft: '0px',
                        marginRight: '17px',
                      }}
                      onChange={(e) => setSoldOut(true)}
                    />
                    <span>Yes</span>{' '}
                    <input
                      type="radio"
                      name="sold"
                      value="No"
                      checked={!isSoldOut}
                      style={{
                        marginTop: '12px',
                        marginLeft: '0px',
                        marginRight: '17px',
                      }}
                      onChange={(e) => setSoldOut(false)}
                    />
                    <span>No</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>
                      {/* Meer informatie: */}
                      {t('Add_Product.addProduct_label_moreInfo')}
                    </b>
                  </label>
                  <textarea
                    name="description"
                    value={articleOption ? articleOption.description : ''}
                    className="form-control"
                    placeholder={t('Add_Product.addProduct_placeholder_moreInfo')}
                    onChange={(e) => changearticledata(e)}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              name={t('Add_Product.addProduct_button_cancel')}
              btnLongWidth={false}
              type="button"
              onClick={(e: any) => refresh(e)}
            />

            <Button
              name={t('Add_Product.addProduct_button_submit')}
              dangerButton={true}
              btnLongWidth={false}
              type="submit"
            />
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
};
export default EditArticleModel;
