import React, { FC, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { Button, InputField } from './index';
import { API } from '../api';
import { useTranslation } from 'react-i18next';

const WorkingHoursModal: FC<any> = (props) => {
  const { t } = useTranslation();
  const [Workinghour, setWorkinghour] = useState<any>('');

  useEffect(() => {
    if (typeof props.workinghourobj === 'undefined') {
      setWorkinghour('');
    } else {
      setWorkinghour(props.workinghourobj);
    }
  }, [props]);


  useEffect(() => {
    if (props.modal) {
      setTimeout(() => {
        let name = document.getElementById("vanaf_validation") as HTMLInputElement
        name.addEventListener("input", function (e) {
          name.setCustomValidity(""); //remove message when new text is input
        });
        name.addEventListener("invalid", function (e) {
          name.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
        });

        let name2 = document.getElementById("tot_validation") as HTMLInputElement
        name2.addEventListener("input", function (e) {
          name2.setCustomValidity(""); //remove message when new text is input
        });
        name2.addEventListener("invalid", function (e) {
          name2.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
        });
      }, 100)

    }
  }, [props.modal]);


  const refresh = () => {
    window.location.reload();
  };

  const changeworkinghour = (e: any) => {
    setWorkinghour(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const weekday = e.target.elements.weekday.value;
    const to_hour = e.target.elements.to_hour.value;
    const from_hour = e.target.elements.from_hour.value;
    const id = e.target.elements.id.value;
    const pickoption = e.target.elements.pickupoption.value;
    const deliveryoption = e.target.elements.deliveryoption.value;

    if (pickoption === 'false' && deliveryoption === 'false') {
      toast.error(t('Add_Edit_hours.addOpeningHrs_popup_deDelivery_Pickup'), { containerId: 'B' });

      return 0;
    }

    const restid = localStorage.getItem('rest_id');

    if (id === '') {
      await API.restaurantApi
        .createRestaurantWorkingHours(
          {
            restaurant: restid as string,
            weekday,
            to_hour,
            from_hour,
            pickup_option: pickoption,
            delivery_option: deliveryoption,
          },
          restid as string
        )
        .then((response) => {
          toast.success(t('Add_Edit_hours.addOpeningHrs_successfully_add_msg'), { containerId: 'B' });
          props.toggle && props.toggle();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Something wrong', { containerId: 'B' });
        });
    } else {
      await API.restaurantApi
        .updateRestaurantWorkingHours(
          {
            restaurant: restid as string,
            weekday,
            to_hour,
            from_hour,
            pickup_option: pickoption,
            delivery_option: deliveryoption,
          },
          id
        )
        .then((response) => {
          toast.success(t('Add_Edit_hours.editOpeningHrs_successfully_updated_msg'), { containerId: 'B' });
          props.toggle && props.toggle();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Something wrong', { containerId: 'B' });
          props.toggle && props.toggle();
        });
    }
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        {props.workinghourid === 'new' ? t('Add_Edit_hours.addOpeningHrs_title') : t('Add_Edit_hours.editOpeningHrs_title')}
      </ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="form-group text-left">
              <InputField className="form-control" name="id" type="hidden" value={Workinghour.id} />
            </div>
            <div className="col-sm-12">
              <div className="form-group text-left">
                <label>
                  {/* Choose Day Of Week: */}
                  {t('Add_Edit_hours.addOpeningHrs_label_select_weekday')}
                  <select
                    className="form-control mt-2"
                    name="weekday"
                    value={Workinghour.weekday}
                    onChange={(e) => changeworkinghour(e)}
                  >
                    <option value="Monday">
                      {/* Maandag */}
                      {t('Add_Edit_hours.openingHrs_weekday_mon')}
                    </option>
                    <option value="Tuesday">
                      {/* Dinsdag */}
                      {t('Add_Edit_hours.openingHrs_weekday_Tues')}
                    </option>
                    <option value="Wednesday">
                      {/* Woensdag */}
                      {t('Add_Edit_hours.openingHrs_weekday_Wed')}
                    </option>
                    <option value="Thursday">
                      {/* Donderdag */}
                      {t('Add_Edit_hours.openingHrs_weekday_Thurs')}
                    </option>
                    <option value="Friday">
                      {/* Vrijdag */}
                      {t('Add_Edit_hours.openingHrs_weekday_fri')}
                    </option>
                    <option value="Saturday">
                      {/* Zaterdag */}
                      {t('Add_Edit_hours.openingHrs_weekday_sat')}
                    </option>
                    <option value="Sunday">
                      {/* Zondag */}
                      {t('Add_Edit_hours.openingHrs_weekday_sun')}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group text-left">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="" className="small">
                  {/* Vanaf */}
                  {t('Add_Edit_hours.addOpeningHrs_label_from')}
                </label>
                <InputField
                  className="form-control"
                  id="vanaf_validation"
                  required
                  name="from_hour"
                  placeholder={t('Add_Edit_hours.addOpeningHrs_placeholder_from')}
                  value={Workinghour.from_hour}
                  onChange={(e: any) => changeworkinghour(e)}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group text-left">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="" className="small">
                  {/* Tot */}
                  {t('Add_Edit_hours.addOpeningHrs_label_until')}
                </label>
                <InputField
                  className="form-control"
                  id="tot_validation"
                  required
                  placeholder={t('Add_Edit_hours.addOpeningHrs_placeholder_until')}
                  name="to_hour"
                  value={Workinghour.to_hour}
                  onChange={(e: any) => changeworkinghour(e)}
                />
              </div>
            </div>
            <div className="form-group col-sm-12">
              <label htmlFor="delivery" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {/* Levering optie: */}
                {t('Add_Edit_hours.addOpeningHrs_label_delivery_option')}
              </label>
              <select
                value={Workinghour.delivery_option}
                onChange={(e) => changeworkinghour(e)}
                name="deliveryoption"
                className="form-control"
                id="delivery"
              >
                <option value="true">
                  {/* ON */}
                  {t('Add_Edit_hours.addOpeningHrs_option_delivery_on')}
                </option>
                <option value="false">
                  {/* OFF */}
                  {t('Add_Edit_hours.addOpeningHrs_option_delivery_off')}
                </option>
              </select>
            </div>
            <div className="form-group col-sm-12">
              <label htmlFor="pickoption" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {/* Afhaal optie: */}
                {t('Add_Edit_hours.addOpeningHrs_label_pickUp_option')}
              </label>
              <select
                value={Workinghour.pickup_option}
                onChange={(e) => changeworkinghour(e)}
                name="pickupoption"
                className="form-control"
                id="pickoption"
              >
                <option value="true">
                  {/* ON */}
                  {t('Add_Edit_hours.addOpeningHrs_option_pickUp_on')}
                </option>
                <option value="false">
                  {/* OFF */}
                  {t('Add_Edit_hours.addOpeningHrs_option_pickUp_off')}
                </option>
              </select>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name={t('Add_Edit_hours.addOpeningHrs_button_cancel')}
            type="reset" btnLongWidth={false} onClick={refresh} />
          <Button name={t('Add_Edit_hours.addOpeningHrs_button_save')}
            type="submit" dangerButton={true} btnLongWidth={false} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default WorkingHoursModal;
