import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { getRestaurantData } from '../../store/actions';
import { API } from '../../api';
import { API_BASE_URL, MAIN_APP_URL } from '../../services/urls';
import {
  Button,
  DownloadQR,
  Header,
  InputField,
  Sidebar,
  WorkingHoursModal,
} from '../../Components';
import './style.css';
import { useTranslation } from 'react-i18next';

let ps: any;

const Dashboard: FC = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [qrmodal, setQrModal] = useState(false);
  const [restaurantData, setRestaurantData] = useState<any>([]);
  const [workinghoursData, setworkinghoursData] = useState([]);
  const [workinghourId, setworkinghourId] = useState(0);
  const [workinghourobj, setworkinghourobj] = useState<any>(0);
  const { id } = useParams<any>();
  const restid = localStorage.getItem('rest_id') as string;
  const dutchWeekDays = new Map([
    ['Monday', 'Maandag'],
    ['Tuesday', 'Dinsdag'],
    ['Wednesday', 'Woensdag'],
    ['Thursday', 'Donderdag'],
    ['Friday', 'Vrijdag'],
    ['Saturday', 'Zaterdag'],
    ['Sunday', 'Zondag'],
  ]);
  const mainPanel = useRef<any>();

  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);

  const dispatch = useDispatch();

  const getWorkingHours = () => {
    API.restaurantApi
      .getRestaurantWorkingHours(restid)
      .then((res) => {
        setworkinghoursData(res.data);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    setRestaurantData(restaurant);
  }, [restaurant]);

  useEffect(() => {
    // @ts-ignore
    document.getElementById('dashboard_colorchange').style.color = 'darkred';
    dispatch(getRestaurantData(restid));
    getWorkingHours();

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [qrmodal, modal, id, restid]);

  const toggle = (id: any, workinghour: any) => {
    setModal(!modal);
    setworkinghourId(id);
    setworkinghourobj(workinghour);
    getWorkingHours();
  };

  const deleteWorkinghour = async (id: any) => {
    await API.restaurantApi.deleteRestaurantWorkingHours(id).then((res) => {
      toast.success(t('Add_Edit_hours.addOpeningHrs_successfully_delete_msg'), {
        containerId: 'B',
      });
      getWorkingHours();
    });
  };

  const changerestaurantdata = (e: any) => {
    setRestaurantData({
      ...restaurantData,
      [e.target.name]: [e.target.value],
    });
  };

  const handleChangeStatus = () => {
    let valueA;
    if (restaurantData.Availability === 'OFFLINE') {
      valueA = 'ONLINE';
    } else {
      valueA = 'OFFLINE';
    }
    setRestaurantData({
      ...restaurantData,
      Availability: valueA,
    });
  };

  const handleChangeDelivery = (e: any) => {
    let valueA;
    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      delivery_option: e,
    });
  };

  const handleChangePickup = (e: any) => {
    let valueA;

    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      pickup_option: e,
    });
  };

  const SubmitRestaurantdata = async (e: any) => {
    e.preventDefault();
    console.log("e.target.elements", e.target.elements);

    const restname = e.target.elements.RestaurantName.value;
    const location = e.target.elements.Location.value;
    const zipcode = e.target.elements.zipcode.value;
    // const restaurantImage = e.target.elements.restaurantImage.value;
    const logoimage = e.target.elements?.Logo?.files ? e.target.elements?.Logo?.files[0] : undefined;
    const tablesCount = e.target.elements.NumberOfTables.value;
    let availibilty;
    const pickup_option = true as any;
    const delivery_option = true as any;
    const paynowbutton = e.target.elements.paynowbutton.value;
    const paylaterbutton = e.target.elements.paylaterbutton.value;
    const askforbillbutton = e.target.elements.askforbillbutton.value;
    const askforservicebutton = e.target.elements.askforservicebutton.value;
    const restaurantImage = e.target.elements?.image?.files ? e.target.elements?.image?.files[0] : undefined;
    console.log("e.target.elements?.image", e.target.elements?.image);

    console.log("restaurantImage", restaurantImage);

    const slug = e.target.elements.slug.value;
    const enable_sms = e.target.elements.enable_sms.value;
    const max_order = e.target.elements.max_order.value;
    const time_interval = e.target.elements.time_interval.value;
    const delivery_cost = e.target.elements.delivery_cost.value as any;
    const min_amount_for_free_delivery = e.target.elements.min_amount_for_free_delivery.value;
    let availablity_zone_zipcodes;
    if (
      e.target.elements.availablity_zone_zipcodes.value.replaceAll(/,/g, ' ').split(' ').pop() ===
      ''
    ) {
      availablity_zone_zipcodes = e.target.elements.availablity_zone_zipcodes.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      availablity_zone_zipcodes = e.target.elements.availablity_zone_zipcodes.value
        .replaceAll(/,/g, ' ')
        .split(' ');
    }
    const service_cost_fixed_amount = e.target.elements.service_cost_fixed_amount.value;
    const service_cost_percentage = e.target.elements.service_cost_percentage.value;
    const service_cost_type = e.target.elements.service_cost_type.value;
    const title_ = e.target.elements.page_title.value;
    const defaultMinutes_ = e.target.elements.defaultMinutes.value;
    const customer_msg_ = e.target.elements.customer_msg.value;
    let meta_titles = [];
    if (e.target.elements.meta_titles.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_titles = e.target.elements.meta_titles.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_titles = e.target.elements.meta_titles.value.replaceAll(/,/g, ' ').split(' ');
    }
    let meta_descriptions = [];
    if (e.target.elements.meta_descriptions.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_descriptions = e.target.elements.meta_descriptions.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_descriptions = e.target.elements.meta_descriptions.value
        .replaceAll(/,/g, ' ')
        .split(' ');
    }
    let meta_keywords = [];
    if (e.target.elements.meta_keywords.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_keywords = e.target.elements.meta_keywords.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_keywords = e.target.elements.meta_keywords.value.replaceAll(/,/g, ' ').split(' ');
    }

    const email_restaurant = e.target.elements.email_restaurant.value;
    const phone_restaurant = e.target.elements.phone_restaurant.value;

    // Europabank secrets
    const europabank_uid = e.target.elements.europabank_uid.value;
    const europabank_client_secret = e.target.elements.europabank_client_secret.value;
    const europabank_server_secret = e.target.elements.europabank_server_secret.value;
    const europabank_url = e.target.elements.europabank_url.value;

    const formData = new FormData();

    formData.append('RestaurantName', restname);

    if (e.target.elements.Availability.checked === true) {
      availibilty = 'ONLINE';
    } else {
      availibilty = 'OFFLINE';
    }

    if (restaurantImage !== undefined) {
      formData.append('image', restaurantImage);
    }
    formData.append('Location', location);
    if (logoimage !== undefined) {
      formData.append('Logo', logoimage);
    }

    formData.append('askforbillbutton', askforbillbutton);
    formData.append('askforservicebutton', askforservicebutton);
    formData.append('enable_sms', enable_sms);
    formData.append('paynowbutton', paynowbutton);
    formData.append('paylaterbutton', paylaterbutton);

    formData.append('NumberOfTables', tablesCount);
    formData.append('Availability', availibilty);
    formData.append('pickup_option', pickup_option);
    formData.append('delivery_option', delivery_option);

    formData.append('slug', slug);
    formData.append('max_order', max_order);
    formData.append('time_interval', time_interval);
    formData.append('zipcode', zipcode);

    formData.append('delivery_cost', parseFloat(String(+delivery_cost)).toFixed(2));
    formData.append(
      'min_amount_for_free_delivery',
      parseFloat(String(+min_amount_for_free_delivery)).toFixed(2)
    );
    formData.append('availablity_zone_zipcodes', JSON.stringify(availablity_zone_zipcodes));
    formData.append('meta_titles', JSON.stringify(meta_titles));
    formData.append('meta_keywords', JSON.stringify(meta_keywords));

    formData.append('meta_descriptions', JSON.stringify(meta_descriptions));

    formData.append('service_cost_fixed_amount', service_cost_fixed_amount);
    formData.append('service_cost_percentage', service_cost_percentage);
    formData.append('service_cost_type', service_cost_type);
    formData.append('page_title', title_);

    formData.append('defaultMinutes', defaultMinutes_);
    formData.append('customer_msg', customer_msg_);

    formData.append('email_restaurant', email_restaurant);
    formData.append('phone_restaurant', phone_restaurant);

    formData.append('europabank_uid', europabank_uid);
    formData.append('europabank_client_secret', europabank_client_secret);
    formData.append('europabank_server_secret', europabank_server_secret);
    formData.append('europabank_url', europabank_url);
    formData.append('visible_on_webapp', e.target.elements.visible_on_webapp.checked);

    await API.restaurantApi
      .updateRestaurantData(formData, restid)
      .then(() => {
        toast.success(t('Bewerk_restaurant.editRestuarant_successfully_updated'), {
          containerId: 'B',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        window.location.reload();
      });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row mr-md-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-9 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5 text-center">
            {/* <span className="h4 mb-3">Bewerk restaurant </span> */}
            <span className="h4 mb-3">{t('Bewerk_restaurant.editRestuarant_title')}</span>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 mt-3 offsetMd5">
            <form onSubmit={(e) => SubmitRestaurantdata(e)}>
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {/* Naam restaurant: */}
                {t('Bewerk_restaurant.editRestuarant_label_name')}
              </label>
              <InputField
                className="form-control"
                name="RestaurantName"
                placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_name')}
                value={restaurantData.RestaurantName || ''}
                onChange={(e: any) => changerestaurantdata(e)}
              />
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {/* Locatie: */}
                {t('Bewerk_restaurant.editRestuarant_label_address')}
              </label>
              <textarea
                className="form-control mt-1"
                rows={6}
                name="Location"
                placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_address')}
                value={restaurantData.Location}
                onChange={(e) => changerestaurantdata(e)}
              />
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {/* Availablity zone :(comma seperated) */}
                {t('Bewerk_restaurant.editRestuarant_label_availability_zipCodes')}
              </label>
              <textarea
                className="form-control mt-1"
                rows={8}
                name="availablity_zone_zipcodes"
                placeholder={t(
                  'Bewerk_restaurant.editRestuarant_placeholder_availability_zipCodes'
                )}
                value={restaurantData.availablity_zone_zipcodes}
                onChange={(e) => changerestaurantdata(e)}
              />
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                {/* Postcode: */}
                {t('Bewerk_restaurant.editRestuarant_label_postCode')}
              </label>
              <input
                className="form-control mt-1"
                name="zipcode"
                placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_postCode')}
                value={restaurantData.zipcode || ''}
                onChange={(e) => changerestaurantdata(e)}
              />
              <h6 className="mt-3">
                {/* Banner */}
                {t('Bewerk_restaurant.editRestuarant_label_benner')}
              </h6>
              <div>
                <div className="input-group custom-file-button">
                  <label className="input-group-text" htmlFor="inputGroupFile">
                    {t('Bewerk_restaurant.editRestuarant_Choose_File_benner')}
                  </label>
                  <input type="file" name="image" className="form-control" id="inputGroupFile" />
                </div>

                {/* <input
                  id="restimage"
                  name="image"
                  type="file"
                  className="form-control mt-3 p-1"
                  // placeholder={t('Bewerk_restaurant.editRestuarant_Choose_File_benner')}
                  // value={restaurantData.image}
                /> */}

                <h6>
                  {/* Vorige afbeelding */}
                  {t('Bewerk_restaurant.editRestuarant_banner_preview')}
                </h6>
                {restaurantData.image && (
                  <img
                    className="restaurant_images"
                    id="blah"
                    src={API_BASE_URL + restaurantData.image}
                    onChange={(e) => changerestaurantdata(e)}
                    alt=""
                  />
                )}
              </div>
              <h6 className="mt-3">
                {/* Update Logo */}
                {t('Bewerk_restaurant.editRestuarant_label_logo')}
              </h6>
              <div>
                <div className="input-group custom-file-button">
                  <label className="input-group-text" htmlFor="inputGroupFileLogo">
                    {t('Bewerk_restaurant.editRestuarant_Choose_file_logo')}
                  </label>
                  <input type="file" name="Logo" className="form-control" id="inputGroupFileLogo" />
                </div>

                {/* <input
                  id="logo"
                  name="Logo"
                  type="file"
                  className="form-control mt-3 p-1"
                  onChange={(e) => changerestaurantdata(e)}
                /> */}
                <h6>
                  {/* Vorige afbeelding */}
                  {t('Bewerk_restaurant.editRestuarant_logo_preview')}
                </h6>
                {restaurantData.Logo && (
                  <img
                    id="blah"
                    className="restaurant_images"
                    src={API_BASE_URL + restaurantData.Logo}
                    onChange={(e) => changerestaurantdata(e)}
                    alt=""
                  />
                )}
              </div>
              <div className="form-group">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {/* Aantal tafels met unieke qr code: */}
                  {t('Bewerk_restaurant.editRestuarant_label_numberOfTables')}
                </label>
                <input
                  name="NumberOfTables"
                  className="form-control"
                  type="number"
                  placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_numberOfTables')}
                  min="0"
                  value={restaurantData.NumberOfTables || ''}
                  onChange={(e) => changerestaurantdata(e)}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="availibility"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {/* Status restaurant: */}
                  {t('Bewerk_restaurant.editRestuarant_label_staus')}
                </label>
                <div className="form-group">
                  <Switch
                    onChange={() => handleChangeStatus()}
                    onColor="#dc3545"
                    name="Availability"
                    checked={restaurantData.Availability === 'ONLINE'}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="defaultMinutes"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {/* Bereidingstijd in min: */}
                  {t('Bewerk_restaurant.editRestuarant_label_preparation_time')}
                </label>
                <div className="form-group">
                  <input
                    name="defaultMinutes"
                    className="form-control"
                    type="number"
                    placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_preparation_time')}
                    min="0"
                    value={restaurantData.defaultMinutes || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor="customer_msg"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {/* Bericht indien gesloten: */}
                  {t('Bewerk_restaurant.editRestuarant_label_msg_restuarant_close')}
                </label>
                <div className="form-group">
                  <input
                    name="customer_msg"
                    className="form-control"
                    type="input"
                    id="customer_msg"
                    placeholder="Enter msg"
                    value={restaurantData.customer_msg || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group">
                  <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {/* Europabank UID */}
                    {t('Bewerk_restaurant.editRestuarant_label_europaBankUID')}
                  </label>
                  <input
                    name="europabank_uid"
                    className="form-control"
                    type="text"
                    id="europabank_uid"
                    placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_europaBankUID')}
                    value={restaurantData.europabank_uid || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {/* Europabank client secret */}
                    {t('Bewerk_restaurant.editRestuarant_label_europaBank_client_secret')}
                  </label>
                  <input
                    name="europabank_client_secret"
                    className="form-control"
                    type="text"
                    id="europabank_client_secret"
                    placeholder={t(
                      'Bewerk_restaurant.editRestuarant_placeholder_europaBank_client_secret'
                    )}
                    value={restaurantData.europabank_client_secret || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {/* Europabank server secret */}
                    {t('Bewerk_restaurant.editRestuarant_label_europaBank_server_secret')}
                  </label>
                  <input
                    name="europabank_server_secret"
                    className="form-control"
                    type="text"
                    id="europabank_server_secret"
                    placeholder={t(
                      'Bewerk_restaurant.editRestuarant_placeholder_europaBank_server_secret'
                    )}
                    value={restaurantData.europabank_server_secret || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {/* Europabank url */}
                    {t('Bewerk_restaurant.editRestuarant_label_europaBank_url')}
                  </label>
                  <input
                    name="europabank_url"
                    className="form-control"
                    type="text"
                    id="europabank_url"
                    placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_europaBank_url')}
                    value={restaurantData.europabank_url || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {/* Online laten betalen? */}
                    {t('Bewerk_restaurant.editRestuarant_label_pay_online')}
                  </label>
                  <select
                    value={restaurantData.paynowbutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="paynowbutton"
                    className="form-control"
                    id="paynow"
                  >
                    <option value="true">
                      {/* ON */}
                      {t('Bewerk_restaurant.editRestuarant_option_pay_online_on')}
                    </option>
                    <option value="false">
                      {/* OFF */}
                      {t('Bewerk_restaurant.editRestuarant_option_pay_online_off')}
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {/* Ter plaatse laten betalen? */}
                    {t('Bewerk_restaurant.editRestuarant_placeholder_payOnSpot')}
                  </label>
                  <select
                    value={restaurantData.paylaterbutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="paylaterbutton"
                    className="form-control"
                    id="paynow"
                  >
                    <option value="true">
                      {/* ON */}
                      {t('Bewerk_restaurant.editRestuarant_option_payOnsite_on')}
                    </option>
                    <option value="false">
                      {/* OFF */}
                      {t('Bewerk_restaurant.editRestuarant_option_payOnsite_Off')}
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {/* Service aanvraag aan tafel */}
                    {t('Bewerk_restaurant.editRestuarant_label_serviceRequest_at_table')}
                  </label>
                  <select
                    value={restaurantData.askforservicebutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="askforservicebutton"
                    className="form-control"
                    id="askforservicebutton"
                  >
                    <option value="true">
                      {/* ON */}
                      {t('Bewerk_restaurant.editRestuarant_option_serviceRequest_on')}
                    </option>
                    <option value="false">
                      {/* OFF */}
                      {t('Bewerk_restaurant.editRestuarant_option_serviceRequest_off')}
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">
                    {/* Rekening aanvraag aan tafel */}
                    {t('Bewerk_restaurant.editRestuarant_label_billRequest_at_table')}
                  </label>
                  <select
                    value={restaurantData.askforbillbutton}
                    onChange={(e) => changerestaurantdata(e)}
                    name="askforbillbutton"
                    className="form-control"
                    id="askforbillbutton"
                  >
                    <option value="true">
                      {/* ON */}
                      {t('Bewerk_restaurant.editRestuarant_option_billRequest_at_table_on')}
                    </option>
                    <option value="false">
                      {/* OFF */}
                      {t('Bewerk_restaurant.editRestuarant_option_billRequest_at_table_off')}
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="enable_sms"
                    className="control-label font-weight-bold m-0 mb-2 mt-3"
                  >
                    {/* Activeer smsfunctie */}
                    {t('Bewerk_restaurant.editRestuarant_label_active_sms')}
                  </label>
                  <select
                    value={restaurantData.enable_sms}
                    onChange={(e) => changerestaurantdata(e)}
                    name="enable_sms"
                    className="form-control"
                    id="enable_sms"
                  >
                    <option value="true">
                      {/* ON */}
                      {t('Bewerk_restaurant.editRestuarant_label_active_sms_on')}
                    </option>
                    <option value="false">
                      {/* OFF */}
                      {t('Bewerk_restaurant.editRestuarant_label_active_sms_off')}
                    </option>
                  </select>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="service_cost_type"
                    className="control-label font-weight-bold m-0 mb-2 mt-3"
                  >
                    {/* Service cost type */}
                    {t('Bewerk_restaurant.editRestuarant_label_serviceCostType')}
                  </label>
                  <select
                    value={restaurantData.service_cost_type}
                    onChange={(e) => changerestaurantdata(e)}
                    name="service_cost_type"
                    className="form-control"
                    id="service_cost_type"
                  >
                    <option value="fixed_amount">
                      {/* Fixed amount */}
                      {t('Bewerk_restaurant.editRestuarant_option_serviceCostType_fixed_amount')}
                    </option>
                    <option value="percentage">
                      {/* Percentage */}
                      {t('Bewerk_restaurant.editRestuarant_option_serviceCostType_percentage')}
                    </option>
                  </select>
                </div>
                <label
                  htmlFor="service_cost_fixed_amount"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {/* Service cost fixed amount */}
                  {t('Bewerk_restaurant.editRestuarant_label_serviceCost_fixed_amount')}
                </label>
                <InputField
                  className="form-control"
                  disabled={
                    (restaurantData.service_cost_type &&
                      restaurantData.service_cost_type[0] === 'percentage') ||
                    restaurantData.service_cost_type === 'percentage'
                  }
                  name="service_cost_fixed_amount"
                  placeholder={t(
                    'Bewerk_restaurant.editRestuarant_placeholder_serviceCost_fixed_amount'
                  )}
                  value={restaurantData.service_cost_fixed_amount || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="service_cost_percentage"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {/* Service cost percentage */}
                  {t('Bewerk_restaurant.editRestuarant_placeholder_serviceCost_percentage_amount')}
                </label>
                <InputField
                  className="form-control"
                  disabled={
                    (restaurantData.service_cost_type &&
                      restaurantData.service_cost_type[0] === 'fixed_amount') ||
                    restaurantData.service_cost_type === 'fixed_amount'
                  }
                  name="service_cost_percentage"
                  placeholder="%"
                  value={restaurantData.service_cost_percentage || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="delveryCost"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {/* Delivery Cost */}
                  {t('Bewerk_restaurant.editRestuarant_label_delivery_cost')}
                </label>
                <InputField
                  className="form-control"
                  name="delivery_cost"
                  placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_delivery_cost')}
                  value={restaurantData.delivery_cost || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />

                <label
                  htmlFor="freeDelivery"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  {/* Free Delivery (above amount) */}
                  {t('Bewerk_restaurant.editRestuarant_label_free_delivery_amount')}
                </label>
                <InputField
                  className="form-control"
                  name="min_amount_for_free_delivery"
                  placeholder={t(
                    'Bewerk_restaurant.editRestuarant_placeholder_free_delivery_amount'
                  )}
                  value={restaurantData.min_amount_for_free_delivery || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {/* Page Title */}
                  {t('Bewerk_restaurant.editRestuarant_label_pageTitle')}
                </label>
                <InputField
                  className="form-control"
                  name="page_title"
                  placeholder="Page Title"
                  value={restaurantData.page_title || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {/* Meta Titles(comma seperated) */}
                  {t('Bewerk_restaurant.editRestuarant_label_metaTitles')}
                </label>
                <textarea
                  className="form-control mt-1"
                  rows={4}
                  name="meta_titles"
                  placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_metaTitles')}
                  value={restaurantData.meta_titles}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {/* Meta Descriptions(comma seperated) */}
                  {t('Bewerk_restaurant.editRestuarant_label_metaDescription')}
                </label>
                <textarea
                  className="form-control mt-1"
                  rows={4}
                  name="meta_descriptions"
                  placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_metaDescription')}
                  value={restaurantData.meta_descriptions}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {/* Meta Keywords(comma seperated) */}
                  {t('Bewerk_restaurant.editRestuarant_label_metaKeyword')}
                </label>
                <textarea
                  className="form-control mt-1"
                  rows={4}
                  name="meta_keywords"
                  placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_metaKeyword')}
                  value={restaurantData.meta_keywords}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {/* Slug */}
                  {t('Bewerk_restaurant.editRestuarant_label_slug')}
                </label>
                <InputField
                  className="form-control"
                  required
                  name="slug"
                  placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_slug')}
                  value={restaurantData.slug || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {/* Max aantal bestellingen */}
                  {t('Bewerk_restaurant.editRestuarant_label_max_num_orders')}
                </label>
                <InputField
                  className="form-control"
                  name="max_order"
                  placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_max_num_orders')}
                  value={restaurantData.max_order || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {/* Per tijdsinterval (in min) */}
                  {t('Bewerk_restaurant.editRestuarant_label_per_time_interval')}
                </label>
                <InputField
                  className="form-control"
                  name="time_interval"
                  placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_per_time_interval')}
                  value={restaurantData.time_interval || 'z'}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {/* Email restaurant: */}
                  {t('Bewerk_restaurant.editRestuarant_label_restuarant_email')}
                </label>
                <InputField
                  className="form-control"
                  name="email_restaurant"
                  type="email"
                  placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_restuarant_email')}
                  value={restaurantData.email_restaurant || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  {/* Tel restaurant: */}
                  {t('Bewerk_restaurant.editRestuarant_label_telephone')}
                </label>
                <InputField
                  className="form-control"
                  name="phone_restaurant"
                  type="tel"
                  pattern="[0-9]+"
                  placeholder={t('Bewerk_restaurant.editRestuarant_placeholder_telephone')}
                  value={restaurantData.phone_restaurant || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />

                <div className="form-group mb-2 mt-3 d-flex">
                  <label
                    htmlFor="availibility"
                    className="control-label font-weight-bold m-0 "
                  >
                    {/* Status restaurant: */}
                    {t('Bewerk_restaurant.editRestuarant_label_visible_on_webapp')}
                  </label>
                  <div className='pl-3'>
                    <Switch
                      onChange={() => {
                        setRestaurantData({
                          ...restaurantData,
                          visible_on_webapp: !restaurantData.visible_on_webapp,
                        });
                      }}
                      onColor="#dc3545"
                      name="visible_on_webapp"
                      checked={restaurantData.visible_on_webapp || false}

                    />
                  </div>
                </div>
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  URL:
                </label>
                <a href={`${MAIN_APP_URL}/restaurant/${restaurantData.slug}`} target='_blank'>
                  {/* Visit Your URL! */}
                  {t('Bewerk_restaurant.editRestuarant_navigate_site')}
                </a>
              </div>

              <div className="text-right">
                <Button
                  name={t('Bewerk_restaurant.editRestuarant_button_save')}
                  htmlType="submit"
                  dangerButton={true}
                  btnLongWidth={false}
                />
              </div>
            </form>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5">
            <div className="p-3">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={() => toggle('new', 'new')}
                className="btn btn-success rounded-0 shadow-none"
              >
                {/* + Voeg tijdslot toe */}
                {t('Opening_hours.openingHrs_button_add')}
              </button>
              <div className="row border border-secondary mt-2">
                <div className="col-sm-12 text-center mt-2">
                  <h4>
                    {/* Openingsuren */}
                    {t('Opening_hours.openingHrs_table_title')}
                  </h4>
                </div>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">
                        {/* Dag */}
                        {t('Opening_hours.openingHrs_table_day')}
                      </th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">
                        {/* Vanaf */}
                        {t('Opening_hours.openingHrs_table_from')}
                      </th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">
                        {/* Tot */}
                        {t('Opening_hours.openingHrs_table_until')}
                      </th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">
                        {/* Levering / Afhaal */}
                        {t('Opening_hours.openingHrs_table_delivery')} /{' '}
                        {t('Opening_hours.openingHrs_table_takeaway')}
                      </th>
                      <th className="control-label font-weight-bold m-0 mb-2 mt-3">
                        {/* Actie */}
                        {t('Opening_hours.openingHrs_table_action')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {workinghoursData &&
                      workinghoursData.map((workinghour: any, index) => {
                        return (
                          <tr key={index}>
                            <td>{dutchWeekDays.get(workinghour.weekday)}</td>
                            <td>{workinghour.from_hour}</td>
                            <td>{workinghour.to_hour}</td>
                            <td>
                              <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}>
                                  {' '}
                                  {/* Levering */}
                                  {t('Opening_hours.openingHrs_table_delivery')}:{' '}
                                  <b>{workinghour.delivery_option ? 'ON' : 'OFF'}</b>
                                </div>
                                <div style={{ width: '50%' }}>
                                  {' '}
                                  {/* Afhaal */}
                                  {t('Opening_hours.openingHrs_table_takeaway')}:{' '}
                                  <b>{workinghour.pickup_option ? 'ON' : 'OFF'}</b>
                                </div>
                              </div>
                            </td>

                            <td>
                              <i
                                className="fa fa-pencil cursr"
                                aria-hidden="true"
                                id={workinghour.id}
                                onClick={() => toggle(workinghour.id, workinghour)}
                              />
                              <i
                                className="fa fa-trash pl-2 cursr"
                                aria-hidden="true"
                                id={workinghour.id}
                                onClick={() => deleteWorkinghour(workinghour.id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WorkingHoursModal
        workinghourobj={workinghourobj}
        modal={modal}
        toggle={toggle}
        workinghourid={workinghourId}
      />
      <DownloadQR />
    </>
  );
};
export default Dashboard;
