import React from 'react';
import { css } from '@emotion/react';

import FadeLoader from 'react-spinners/FadeLoader';

const Loader: React.FC = () => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #be0a27;
    margin-top: 20rem;
    position: fixed;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
    background: green;
    opacity: 1;
    z-index: 1000;
    loadingparentelement: 'body';
  `;

  // @ts-ignore
  return <FadeLoader css={override} width={5} height={15} margin="2" color="#be0a27" />;
};
export default Loader;
