import { toast } from 'react-toastify';
import React, { FC, useEffect, useRef } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import { Header, Sidebar, Button } from '../../Components';
import './style.css';
import { API } from '../../api';
import { useTranslation } from 'react-i18next';

let ps: any;

const UploadCSV: FC = () => {
  const { t } = useTranslation();
  const mainPanel = useRef<any>();
  useEffect(() => {
    document.title = 'Upload CSV';
    // @ts-ignore
    document.getElementById('csv_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const restid = localStorage.getItem('rest_id');
    const csv = e.target.elements.csvupload.files[0];
    console.log('csv.type', csv.type);
    if (csv.type === 'application/vnd.ms-excel' || csv.type === 'text/csv') {
      const formData = new FormData();
      formData.append('csv_file', csv);
      await API.restaurantApi.uploadArticleCSV(formData, restid as string);
      toast.success(t("CSV_Upload.csvUpload_success_msg"), { containerId: 'B' });
    } else {
      toast.error(t("CSV_Upload.csvUpload_validation_file_extention"), { containerId: 'B' });
    }
  };

  return (
    <>
      <Sidebar />
      <form onSubmit={(e) => handleSubmit(e)}>
        <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
          <Header />
          <div className="row mr-5 mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-12 col-sm-11 offset-sm-1 col-11 offset-1 mt-3 text-center">
              <span className="h4 mb-3">
                {/* Upload CSV */}
                {t("CSV_Upload.csvUpload_title")}
                </span>
            </div>
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-11 offset-md-1 col-sm-11 offset-sm-1 col-11 offset-1 mt-3 text-left offsetMd5">
              <span className="h6 font-weight-bold">Step 1</span>
            </div>
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-11 offset-sm-1 col-11 offset-1 mt-3 pt-2 border-style offsetMd5">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex">
                    <div />
                    <span className="h6 font-weight-normal text-danger mt-2 cursr smallFont smallFont3">
                      <i className="fa fa-plus" /> 
                      {/* Upload CSV file Here */}
                      {t("CSV_Upload.csvUpload_label_csvFile")}
                    </span>
                    <input className="ml-5 mb-2" name="csvupload" id="file-input" type="file" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-11 offset-md-1 col-sm-11 offset-sm-1 col-11 offset-1 mt-3 text-right">
              <Button name={t("CSV_Upload.csvUpload_button_save")}
              dangerButton={true} btnLongWidth={false} type="submit" />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default UploadCSV;
