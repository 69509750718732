import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

type Props = {
    columns: {
        name: string;
        accessor: string,
        Row: (value: any, row: any) => React.ReactNode
    }[];
    data: any[];
    totalRecords: number;
    pageSize: number;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    pageIndex: number;
    setPageIndex: React.Dispatch<React.SetStateAction<number>>;
    fetchData: (pageSize: number, pageIndex: number) => void;
}

const Table = ({
    columns,
    data,
    totalRecords,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    fetchData
}: Props) => {
    const { t } = useTranslation();
    const hasNext = (pageIndex < Math.ceil(totalRecords / pageSize));
    const hasPrev = (pageIndex > 1);
    const totalPages = Math.ceil(totalRecords / pageSize);
    return (
        <>
            <div className='pt-5'>
                <table className="table table-auto">
                    <thead>
                        <tr>
                            {columns.map((column, key) => {
                                return (
                                    <th key={key}>
                                        {column.name}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((value, key) => {
                                return (
                                    <tr key={key}>
                                        {columns.map((column, index) => {
                                            return (
                                                <td key={index}>
                                                    {column.Row(value[column.accessor], value)}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
                {data.length <= 0 && <p className='flex justify-center items-center p-2 rounded-t border-b sticky top-0 left-0 text-red-600 bg-white'>
                    {t('customer.table.noDataFound')}
                </p>}
                <div className='d-flex flex-row justify-content-between'>
                    <div className='d-flex align-items-center font-weight-bold'>
                        {t('customer.table.totalRecords')}: {totalRecords}
                    </div>
                    <div className='d-flex align-items-center font-weight-bold'>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination pagination-sm">
                                <li className={`page-item ${hasPrev ? 'cursor-pointer' : 'disabled'}`} onClick={() => {
                                    if (hasPrev) {
                                        fetchData(pageSize, pageIndex - 1)
                                    }
                                }}>
                                    <span className="material-icons-outlined page-link">navigate_before</span>
                                </li>

                                {(() => {
                                    var count = 0;
                                    var display = true;
                                    return [...Array(totalPages)].map((elementInArray, i) => {
                                        var index = i + 1;
                                        var totalPage = totalPages;
                                        if (totalPage < 7) {
                                            display = true;
                                            return (

                                                <li key={index} className={`page-item cursor-pointer ${pageIndex === i + 1 && 'active'}`} onClick={() => {
                                                    // setPageIndex(index);
                                                    fetchData(pageSize, index)
                                                }}>
                                                    <span className='page-link px-3'>{i + 1}</span>
                                                </li>
                                            );
                                        }
                                        if (
                                            index === pageIndex - 1 ||
                                            index === pageIndex ||
                                            index === pageIndex + 1 ||
                                            index === 0 ||
                                            index === totalPage - 1 ||
                                            (pageIndex === 0 && index === pageIndex + 2) ||
                                            (pageIndex === totalPage - 1 &&
                                                index === pageIndex - 2) || index === totalPage || index === 1
                                        ) {
                                            display = true;
                                            return (
                                                <li
                                                    key={index}
                                                    onClick={() => {
                                                        // setPageIndex(index);
                                                        fetchData(pageSize, index)
                                                    }}
                                                    className={`page-item cursor-pointer ${pageIndex === i + 1 && 'active'}`}
                                                >

                                                    <span className='page-link px-3'>{i + 1}</span>
                                                </li>
                                            );
                                        }
                                        if (
                                            index !== 0 &&
                                            index !== totalPage - 1 &&
                                            pageIndex - 1 !== pageIndex - 2 &&
                                            count < 2 &&
                                            display
                                        ) {
                                            display = false;
                                            count++;
                                            return (
                                                <li key={index} className="page-item cursor-pointer">
                                                    <span className='page-link px-3'>{"..."}</span>

                                                </li>
                                            );
                                        }
                                        return "";
                                    });
                                })()}

                                <li className={`page-item ${hasNext ? 'cursor-pointer' : 'disabled'}`} onClick={() => {
                                    if (hasNext) {
                                        fetchData(pageSize, pageIndex + 1)
                                    }
                                }}>
                                    <span className="material-icons-outlined page-link">navigate_next</span>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Table