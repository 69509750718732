import React, { FC } from 'react';
import './style.css';

const InputField: FC<any> = (props) => {


  return (

    <input
      id={props.id}
      type={props.type}
      // className="form-control"
      className={props.className}
      placeholder={props.placeholder}
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      max={props.max}
      min={props.min}
      required={props.required}
      minLength={props.minlength}
      disabled={props.disabled}
      pattern={props.pattern}
      style={props?.style || {}}
    />
  );
};

export default InputField;
