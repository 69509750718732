import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import dutch from '../../assets/images/flags/dutch.png';
import france from '../../assets/images/flags/france.png';
import { API } from '../../api';

const language: any = {
  Dutch: {
    name: 'Dutch',
    icon: dutch,
  },
  French: {
    name: 'Français',
    icon: france,
  },
};
const LanguageDropDown = () => {
  const [localLang, setLocalLang] = useState(i18n.language);
  useEffect(() => {
    let storageLang = localStorage.getItem('yopla_lang');
    if (storageLang) {
      changeLanguage(storageLang);
    } else {
      changeLanguage(i18n.language);
    }
  }, []);
  const changeLanguage = (lang: string, reload: boolean = false) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user?.id) {
      const formData = new FormData();
      API.siteApi.changeLanguage(user?.id, { language: lang == 'French' ? 'fr' : 'nl', iscustomer: false }).then((response) => {
        console.log(response);

      }).catch((error) => {
        console.log(error);
      })
    }
    i18n.changeLanguage(lang);
    setLocalLang(lang);
    localStorage.setItem('yopla_lang', lang);
    if (reload) {
      // window.location.reload();
    }

  };
  // const data:any = localLang && language[localLang]
  // console.log(data,"language")

  return (
    <div className="dropdown" style={{ paddingTop: '0.3em', cursor: 'pointer' }}>
      <span data-toggle="dropdown" className="nav-link dropdown-toggle user-action user-email-link">
        <img
          src={language[localLang]?.icon}
          height={'20em'}
          width={'25em'}
          style={{ marginRight: '0.5em' }}
        />
        {localLang}
      </span>
      <ul className="dropdown-menu">
        {Object.keys(language).map((key, index) => {
          return (
            <li
              key={index}
              style={{ marginLeft: '1em', display: 'flex', marginBottom: '0.5em' }}
              onClick={() => changeLanguage(key, true)}
            >
              <img
                src={language[key].icon}
                height={'20em'}
                width={'25em'}
                style={{ marginRight: '0.5em' }}
              />
              <span style={{ width: '4em' }}>{language[key].name}</span>
              {localLang === key && <span className="material-icons-outlined">done</span>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LanguageDropDown;
