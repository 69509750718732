import React from 'react'
import Layout from '../../Components/Layout';
import { Link } from 'react-router-dom';
import { ErrorMessage, Field, Formik, Form as FormikForm, FormikHelpers } from 'formik';
import * as yup from 'yup';
type Props = {}

type FormDataType = {
    name: string;
    address: string;
    phone: string;
}
const CustomerEditForm = (props: Props) => {
    const validationSchema = yup.object({
        name: yup.string().required('Please enter Name.'),
        address: yup.string().required('Please enter address.'),
        phone: yup.string().required('Please enter phone number.').matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Please enter valid phone number"),
    });
    const submitHandler = (values: FormDataType, actions: FormikHelpers<FormDataType>) => {
        console.log(values);

    }
    const initialValues: FormDataType = {
        name: '',
        address: '',
        phone: ''
    }
    return (
        <Layout>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    submitHandler(values, actions);
                }}
                validationSchema={validationSchema}
            >
                {({ errors, setFieldValue, values, resetForm }) => {
                    console.log(errors, values);

                    return (
                        <FormikForm>
                            <div className='d-flex flex-row justify-content-between'>
                                <div className='d-flex flex-row align-items-center'>
                                    <Link to={'/admin/customer'} className='btn btn-light d-flex flex-row align-items-center'>
                                        <span className="material-icons-outlined">
                                            west
                                        </span>
                                    </Link>
                                    <h2 className='font-weight-bold pl-3' style={{ margin: '0px' }}>Edit Customer</h2>
                                </div>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                            <div className='card mt-3 border-0'>
                                <div className='card-body'>
                                    <div className="form-group text-left">
                                        <label className='control-label font-weight-bold m-0 mb-2 mt-3' htmlFor="name">Name</label>
                                        <Field type="text" className="form-control" id="name" name={'name'} placeholder={`Enter name`} />
                                        <span className='text-danger'>
                                            <ErrorMessage name={'name'} />
                                        </span>
                                    </div>
                                    <div className="form-group text-left">
                                        <label className='control-label font-weight-bold m-0 mb-2 mt-3' htmlFor="address">Address</label>
                                        <Field type="text" as={'textarea'} className="form-control" id="address" name={'address'} placeholder={`Enter Address`} />
                                        <span className='text-danger'>
                                            <ErrorMessage name={'address'} />
                                        </span>
                                    </div>
                                    <div className="form-group text-left">
                                        <label className='control-label font-weight-bold m-0 mb-2 mt-3' htmlFor="phone">Phone</label>
                                        <Field type="text" className="form-control" id="phone" name={'phone'} placeholder={'Enter phone number'} />
                                        <span className='text-danger'>
                                            <ErrorMessage name={'phone'} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </FormikForm>)
                }}
            </Formik>
        </Layout>
    )
}

export default CustomerEditForm;