import React, { useEffect, useState } from 'react'
import Layout from '../../Components/Layout'
import { Link } from 'react-router-dom'
import Table from '../../Components/Table'
import axios from 'axios'
import api from '../../api/api'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Loader from '../../Components/Loader'

type Props = {}

const Customer = (props: Props) => {
    const { t } = useTranslation();
    const columns = [
        {
            name: t('customer.table.name'),
            accessor: 'firstname',
            Row: (value: any, row: any) => {
                return (
                    <span>{row?.firstname || ''}&nbsp;{row?.lastname}</span>
                );
            }
        },
        {
            name: t('customer.table.contactDetails'),
            accessor: 'phone',
            Row: (value: any, row: any) => {
                console.log(row, 'pkkkk');

                return (
                    <>
                        {t('customer.table.address')}: {row?.address || ''} {row?.city_state || ''} <br />
                        {t('customer.table.phone')}: {row?.phone || '-'}
                    </>
                );
            }
        },
        {
            name: t('customer.table.totalOrderValue'),
            accessor: 'total_order_amount',
            Row: (value: any, row: any) => {
                return value;
            }
        },
        {
            name: t('customer.table.createdAt'),
            accessor: 'datetime',
            Row: (value: any, row: any) => {
                return `${moment(value).format(
                    'MMMM, DD YYYY HH:mm'
                )}`
            }
        },
    ];
    const [totalRecords, setTotalRecords] = useState(0);
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [is_loading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        fetchData(pageSize, pageIndex);
    }, []);
    const fetchData = (pageSize: number, pageIndex: number) => {
        setIsLoading(true);
        api(`/restaurant/${localStorage.getItem('rest_id')}/customer?limit=${pageSize}&page=${pageIndex}`).then((res) => {
            if (res?.data?.data) {
                setData(res.data.data);
                setTotalRecords(res?.data?.total);
                setPageIndex(pageIndex);
                setPageSize(pageSize);
            }
            setIsLoading(false);

        }).catch((err) => {
            setIsLoading(false);

        })
    }
    return (
        <Layout>
            <div className='d-flex flex-row justify-content-between'>
                <h2 className='font-weight-bold'>{t('customer.title')}</h2>
                <a href={`${process?.env?.REACT_APP_API_URL}/restaurant/${localStorage.getItem('rest_id')}/customer_export_csv`} className="btn btn-success d-flex align-items-center">{t('customer.exportButtonLabel')}</a>
            </div>
            {is_loading && <Loader />}
            <Table columns={columns} data={data} fetchData={fetchData} totalRecords={totalRecords} pageIndex={pageIndex} setPageIndex={setPageIndex} pageSize={pageSize} setPageSize={setPageSize} />
        </Layout>
    )
}

export default Customer;